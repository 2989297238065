
import store from '@/core/store';
import { onMounted, reactive, toRefs } from 'vue';
import VueTradingView from "vue-trading-view/src/vue-trading-view.vue";
import { mapState } from 'vuex';

export default {
   computed:  mapState([
      'selectedSymbol',
      'theme',
      'selectedFuturesDetails'
  ]),
  props:['width' , 'height' ,'isFutures' , 'showTools'],
  components: {
    VueTradingView,
  },
  setup(props ) {
     const mainState = reactive({
      showChart: true,
      mainTheme:store.state.theme,
      coin: props.isFutures ? store.state.selectedFuturesDetails.symbol ? store.state.selectedFuturesDetails.symbol.name :'BTCUSDT' : (store.state.selectedSymbol && store.state.selectedSymbol.spot) ? store.state.selectedSymbol?.spot?.asset : null 
    })
  
    const getSymbol = () => {
        const selectedSymbol =  store.state.selectedSymbol.spot;
        if (selectedSymbol && selectedSymbol.asset)  return selectedSymbol.asset == 'usdt' ? "KUCOIN:"+selectedSymbol.asset.toUpperCase() : "KUCOIN:"+(selectedSymbol.asset.toUpperCase()+"USDT")
        return 'KUCOIN:BTCUSDT'
    }

    const getOptions = () => {
      
      const defaultOption = {
        symbol: getSymbol()=="KUCOIN:GALAXUSDT"?"KUCOIN:GALAUSDT":getSymbol(),
        theme: mainState.mainTheme ? 'light' : 'dark',
        width: props.width ? props.width : '100%',
        interval: "1",
        height: '360px',
      } 
      
      const tools = {
        allow_symbol_change:false,
        toolbar_bg: '#f1f3f6',
        withdateranges:true,
        hide_side_toolbar:false,
        details:true,
        hotlist:true,
      }


      if (props.showTools) return  {
        ...defaultOption,
        ...tools
      } 
      return {...defaultOption}
    }
   
    onMounted(() => {
      
      store.subscribe((mutation, state) => {
          if ((mutation.type == 'onChangeSelectedSymbol') || mainState.mainTheme !== state.theme ) {
            mainState.mainTheme = state.theme;

            mainState.showChart = false;
            setTimeout(() => {
              mainState.showChart = true;
            }, 500);
          }
        })
    })
    return {
      ...toRefs(mainState),
      getSymbol,
      getOptions
    }
  }
};
