
import { onMounted, reactive, toRefs } from 'vue'
import {  useStore } from 'vuex';
import {mapState} from 'pinia';
import QRCodeVue3 from "qrcode-vue3";
import { ToastService } from '@/core/service/toast.service';
import { i18n } from '@/main';
import VOtpInput from 'vue3-otp-input';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import TipsComponent from "../../../shared/tips/tipsComponent.vue";
import {profileStore} from '@/core/store/profile.store';
export default {
    components:{
        QRCodeVue3,
        VOtpInput,
        Accordion,
        AccordionTab,
        TipsComponent
    },
    computed:{
        ...mapState(profileStore , ['toTpDialog' , 'toTpQr'])
    },
    setup () {
        const state = reactive({
            step: 1,
            loading:false,
            delay:0,
            code:null,
            validation:true,
            showHelp:-1
        })
        const store = profileStore();
        const onCloseDialog = () => {
            store.initDialog(1);
            if (!store?.toTpQr) store.generateTotp();
        }

        const generateTotp = () => {
            state.loading = true;

            setTimeout(() => {
                store.generateTotp();
                state.loading = false;
                state.delay = 5000;
            }, state.delay);
        };

        const handleOnChange = (value: string) => {
            state.code = value;
        };
        const Gstore = useStore();
        

        const onCopy = () => {
            ToastService.getInstance().success(i18n.global.tc('copied'))
        }

        const onSubmit = async () => {
             if (!state.code) return state.validation = false;
            state.loading = true;
            const res = await store.BindToTp(state.code);
            if (res) ToastService.getInstance().success(i18n.global.tc('Success'));
            Gstore.commit('logout');
            
            state.loading = false;
            profileStore().toTpDialog = false;

        }

        onMounted(() => {
            generateTotp();
        })
    
        return {
            ...toRefs(state),
            onCloseDialog,
            generateTotp,
            onCopy,
            onSubmit,
            handleOnChange
        }
    }
}
