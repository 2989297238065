import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47ed5768"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "list-coins"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "mx-2 center" }
const _hoisted_6 = { class: "gary center" }
const _hoisted_7 = {
  key: 1,
  class: "d-flex"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "mx-2 center" }
const _hoisted_10 = {
  key: 2,
  class: "d-flex"
}
const _hoisted_11 = { class: "gary" }
const _hoisted_12 = {
  key: 1,
  class: "loading-controller"
}
const _hoisted_13 = { class: "table-texts" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LazyList = _resolveComponent("LazyList")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($props.list.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_LazyList, {
            data: $props.list,
            defaultLoading: false,
            itemsPerRender: 15,
            containerClasses: "list",
            defaultLoadingColor: "var(--text)"
          }, {
            default: _withCtx(({item}) => [
              _createElementVNode("div", {
                class: "selected-coin",
                onClick: ($event: any) => (_ctx.$emit('onSelect' , item))
              }, [
                (!$props.justString)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("img", {
                        onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.onErrorImage && $setup.onErrorImage(...args))),
                        src: `https://apicrypto.qfmarkets.com/icons/64x64/${!$props.isFutures ? $setup.GRemoveSuffix(item.asset).toUpperCase() : item.stock.toUpperCase()}.png`,
                        alt: '?'
                      }, null, 40, _hoisted_4),
                      _createElementVNode("span", _hoisted_5, _toDisplayString(!$props.isFutures ? $props.isSwap ? $setup.GRemoveSuffix(item.asset) : item.asset : item.stock), 1),
                      _createElementVNode("span", _hoisted_6, _toDisplayString(item.name), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("img", {
                        onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.onErrorImage && $setup.onErrorImage(...args))),
                        src: `https://apicrypto.qfmarkets.com/icons/64x64/${item.symbol.toUpperCase()}.png`,
                        alt: '?'
                      }, null, 40, _hoisted_8),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(item.symbol), 1)
                    ])),
                (item.market)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$filter.currency(item.market.price_usd)), 1)
                    ]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_2)
            ]),
            _: 1
          }, 8, ["data"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("noRecordsFound")), 1)
        ]))
  ]))
}