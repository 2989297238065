
import {  onMounted, reactive, toRefs } from 'vue'
import { i18n } from '@/main'
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import { useRoute } from 'vue-router';
import { mapState, useStore  } from 'vuex';
import ToTpFormComponent from './toTpForm/toTpFormComponent.vue';
import unBindTotp from './unBindTotp/unBindTotpForm.vue';

export default {
  computed:{
        ...mapState({
            userDetails:'userDetails'
        })
    },
  components:{
    Accordion,
    AccordionTab,
    ToTpFormComponent,
    unBindTotp
},
  setup () {
    const state = reactive({
      sideList:[],
      activeSide:'i-total',
      assetsDialog:false,
    })

    const store = useStore();

    const initList = () => {
      state.sideList =  [
            {
                label: i18n.global.tc('accountSetting'),
                icon: 'i-total',
                to:'/profile/accountSetting'
            },
      ];

      const route = useRoute();
      state.sideList.forEach(item => {
          if (route.path == item.to)  {
          state.activeSide = item.icon
          }
      })
    }

   

    onMounted(()=> {
      initList();
    });

    const BindToTp = async () => {
      console.log('call BindToTp');
      const res = await store.dispatch('BindToTp' , 'xxx');
      console.log('res ' , res);
      
    }
  
      return {
        ...toRefs(state),
        BindToTp
      }
  }
}
