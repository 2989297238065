import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d065a3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-4" }
const _hoisted_2 = { class: "market-history market-order markets-pair-list mt15" }
const _hoisted_3 = { class: "d-flex flex-row cp" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_emptyBox = _resolveComponent("emptyBox")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$store.state)
        ? (_openBlock(), _createBlock(_component_DataTable, {
            key: 0,
            value: _ctx.$store.state.wsAssets.marketList,
            globalFilterFields: ['asset'],
            paginator: true,
            rows: 10,
            loading: !_ctx.$store.state.wsAssets.marketList,
            sortField: _ctx.sortBy,
            sortOrder: _ctx.sortOrder,
            filters: _ctx.filter,
            "onUpdate:filters": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter) = $event)),
            responsiveLayout: "stack",
            breakpoint: "960px"
          }, {
            empty: _withCtx(() => [
              _createVNode(_component_emptyBox)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "asset",
                header: _ctx.$t('coin'),
                sortable: true
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("img", {
                      onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.onErrorImage && $setup.onErrorImage(...args))),
                      style: {"position":"relative","bottom":"2px"},
                      src: `https://apicrypto.qfmarkets.com/icons/64x64/${slotProps.data.asset.toUpperCase()}.png`,
                      alt: '?'
                    }, null, 40, _hoisted_4),
                    _createElementVNode("span", {
                      class: "cp",
                      onClick: ($event: any) => (slotProps.data.asset !== 'USDT' && _ctx.$router.push(`/coinInfo/${slotProps.data.asset.toUpperCase()}`))
                    }, _toDisplayString(slotProps.data.asset), 9, _hoisted_5)
                  ])
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "price_usd",
                header: _ctx.$t('price'),
                sortable: true
              }, {
                body: _withCtx((slotProps) => [
                  (slotProps.data.price_usd)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$filter.currency(slotProps.data.price_usd)), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "change_rate",
                header: `${_ctx.$t('change')} (24)`,
                sortable: true
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("span", {
                    class: _normalizeClass(Number(slotProps.data.change_rate) >= 0 ? 'green':'red')
                  }, _toDisplayString(Number(slotProps.data.change_rate * 100).toFixed(2)) + "% ", 3)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "circulation_usd",
                header: `${_ctx.$t('circulation')}(USD)`,
                sortable: true
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$filter.round(slotProps.data.circulation_usd)), 1)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "volume_usd",
                header: `${_ctx.$t('volume')}(USD)`,
                sortable: true
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$filter.round(slotProps.data.volume_usd)), 1)
                ]),
                _: 1
              }, 8, ["header"])
            ]),
            _: 1
          }, 8, ["value", "loading", "sortField", "sortOrder", "filters"]))
        : _createCommentVNode("", true)
    ])
  ]))
}