
import { FilterMatchMode } from 'primevue/api';
import { onMounted, reactive, toRefs } from 'vue'
import EmptyBox from '@/app/unit/shared/emptyBox/emptyBox.vue';
import { Constant } from '@/core/constant';
import store, { SpotBalance } from '@/core/store';
import router from '@/core/router/router';
import Checkbox from 'primevue/checkbox';
import { AccountRepository } from '@/core/repository/account.repository';

export default {
    emits: ["initTransfer"],
    components: { EmptyBox,Checkbox },
    setup(props) {
        const state = reactive({
            filter: {
                "global": { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            showBalance: true,
            transferType:0,
            hideSmall:false,
            filteredList:[] as SpotBalance[],
            ordinalList:[],
            available: 0,
            loading:false,
            showPrice:true
        });

        const setFilter = () => {
            state.filteredList = []
            if (state.hideSmall) {
                state.filteredList = state.ordinalList.filter(item => item.balance > 0)
            } else state.filteredList.push(...state.ordinalList);
        }

        const GetSpotBalance = () => {
            getTotalUsdt();
            state.loading = true;
            AccountRepository.getInstance().GetSpotBalance({
            onReceive:(res)=> {
                
                state.ordinalList = [];
                state.available = 0;

                state.filteredList = [...res.filter((item)=> item.symbol.toLowerCase() != 'usd')]
                console.log('res' , res);

                state.ordinalList.push(...state.filteredList)
            }
          })
        }

         const onSelectCoin = (data:string ) => {
            
             console.log(data);
            const selected = store.state.assets.list.find(item => item.asset == data.toUpperCase());
               const payload = {
                data:selected ,
            }
            
            store.commit('onChangeSelectedSymbol' , {...payload});
            router.push('/spotTrade')
        }

        const onErrorImage = (e) => {
            e.target.src = Constant.ErrorImageUrl
        }

        
       const getTotalUsdt = () => {
            state.loading = true;

           const updateList = () => {
                const data = store.state.wsAssets.data;
                console.log(data);
                
               if (store.state.wsAssets.data && state.filteredList.length > 0) {
                   state.available = 0;
                   state.filteredList.forEach((item) => {
                        if (item.balance > 0 && data[item.symbol.toUpperCase()]) {
                          state.available += (Number(data[item.symbol.toUpperCase()]?.price_usd) * item.balance)
                        }
                   })
                    state.loading = false;

                   clearInterval(interval)
               }            
           }
         const interval =  setInterval(updateList , 3000)
       }

       const watchBalance = () => {
            store.subscribe((mutation, state) => {
              if(mutation.type == 'setMarketPrice' ||  mutation.type == 'updateBalance') {
                GetSpotBalance();
              }
        });
    }
       onMounted(() => {
        GetSpotBalance();
        watchBalance();
       })


        return {
            ...toRefs(state),
            onErrorImage,
            onSelectCoin, 
            setFilter,
            GetSpotBalance
        };
    },
}
