

export default {
    props:{
        loadingProfile:{
            type:Boolean
        }
    }
}

