
import { mapState } from 'vuex'
import LazyList from 'lazy-load-list/vue'
import {  reactive, toRefs } from 'vue';
import store from '@/core/store';
import { Constant } from '@/core/constant';
import { GRemoveSuffix } from '@/core/service/utils.service';

export default {
  props:['isFutures' , 'assets'],
  components:{
    LazyList,
  },
  computed: mapState([
    'wsAssets'
  ]),
   setup (props) {
    const state = reactive({
      filterInput:'',
      
    });

    
    const onSelectCoin = (data) => {
      if (props.isFutures) {
             store.commit('onChangeSelectedSymbol' , {
                data:data,
                isFutures:true
            })
            store.dispatch('desConnectFuturesOrderBook')
            store.dispatch('desConnectOrderBook')
            store.dispatch('getFuturesOrderBooks')
      } else {
        store.commit('onChangeSelectedSymbol' , {
          data:data
        })
        store.dispatch('desConnectOrderBook')
        store.dispatch('desConnectFuturesOrderBook');
        store.dispatch('getOrderBooks')
      }
    };
    const onErrorImage = (e) => {
          e.target.src = Constant.ErrorImageUrl;
    }
    return {
      onSelectCoin,
      ...toRefs(state),
      onErrorImage,
      GRemoveSuffix
    }
  }
}
