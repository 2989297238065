
import Tooltip from "primevue/tooltip";
import emptyBox from "@/app/unit/shared/emptyBox/emptyBox.vue";
import { mapState } from "vuex";
import { TradeRepository } from "@/core/repository/trade.repository";
import store from "@/core/store";
import { onMounted, reactive, toRefs, ref, onBeforeUnmount } from "vue-demi";
import { ToastService } from "@/core/service/toast.service";
import { i18n } from "@/main";
import { useConfirm } from "primevue/useconfirm";
import {
  FuturesHistoryInterface,
  PendingInterface,
  SpotHistoryInterface,
} from "@/data/trade.model";
import { IsLegginged } from "@/core/service/utils.service";
import { profileStore } from "@/core/store/profile.store";
import { exportExel } from "@/core/service/exportExel";
import { DataFormatService } from "@/core/service/dataFormat.service";
import { values } from "lodash";

export default {
  props: ["isFutures"],
  components: {
    emptyBox,
  },
  directives: {
    tooltip: Tooltip,
  },
  computed: mapState(["spotPendingOrder"]),
  setup(props) {
    const state = reactive({
      loading: false,
      list: [] as PendingInterface[],
      positions: {
        list: [],
        loading: false,
      },
      history: {
        list: [] as SpotHistoryInterface[],
        loading: false,
      },
      activeTab: 1,
      getAllSymbol: false,
      total: 0,
      currentPage: 1,
      exportLoading: false,
    });
    const intervalList = ref(null);

    const confirm = useConfirm();
    const convertToIso = (date) => {
      const newDate = String(Math.round(Number(date)));
      console.log(newDate);

      const d = newDate.length < 13 ? newDate + "000" : newDate;
      console.log(d);

      const event = new Date(Number(d));
      console.log("event", event);

      return event.toISOString();
    };

    function confirmDelete(event, isSingle = true, id, isPending = true) {
      confirm.require({
        target: event.currentTarget,
        message: i18n.global.tc(
          isSingle ? "confirmDeleteDialog" : "confirmDeleteDialog_2"
        ),
        icon: "pi pi-exclamation-triangle",
        acceptLabel: i18n.global.tc("approve"),
        rejectLabel: i18n.global.tc("cancel"),
        accept: () => {
          if (isSingle) onCancelSingleOrder(id, isPending);
          else cancelAllOrder();
        },
      });
    }

    const getList = () => {
      const token = localStorage.getItem("token");
      if (!!!token || state.activeTab != 1) return null;
      TradeRepository.getInstance().GetPendingOrders({
        query: !state.getAllSymbol
          ? store.state?.selectedSymbol && store.state.selectedSymbol?.spot
            ? store.state.selectedSymbol?.spot?.asset
            : "BTCUSDT"
          : undefined,
        onReceive: (res: PendingInterface[]) => {
          if (state.list.length > 0 && state.list.length != res.length) {
            getHistory(1);
          }
          state.list = [...res];
          if (state.list.length == 0) {
            clearInterval(intervalList.value);
          }
        },
        onError: (e) => {
          clearInterval(intervalList.value);
        },
      });
    };

    const onCancelSingleOrder = (orderid, pending = true) => {
      TradeRepository.getInstance().CancelSingleOrder({
        body: {
          orderid,
          market: store.state.selectedSymbol?.spot?.asset + "USDT",
        },
        onLoadChange: (load) => (state.loading = load),
        onReceive: (res) => {
          console.log("order cancel ....");

          ToastService.getInstance().success(
            `${i18n.global.tc("cancelOrder")} ${i18n.global.tc("Success")}`
          );
          state.list = state.list.filter((item) => item._id !== orderid);
        },
        onError: (err) => {
          profileStore().getUserSpotBalance();
        },
      });
    };

    const cancelAllOrder = () => {
      TradeRepository.getInstance().CancelAllOrder({
        body: {
          market: store.state.selectedSymbol?.spot?.asset,
        },
        onLoadChange: (load) => (state.loading = load),
        onReceive: (res) => {
          // profileStore().getUserSpotBalance();
          ToastService.getInstance().success(
            `${i18n.global.tc("cancelOrder")} ${i18n.global.tc("Success")}`
          );
          state.list = state.list.splice(0, state.list.length - 1);
        },
      });
    };

    const onChangeMarket = () => {
      store.subscribe((mutation, state) => {
        if (
          mutation.type == "onChangeSelectedSymbol" ||
          mutation.type == "onSetOrder"
        ) {
          restartLists();
          getHistory(1);
        } else if (mutation.type == "updateHistoryList") {
          restartLists();
        }
      });
      const restartLists = () => {
        console.log("change market");

        setIntervals();
        getList();
      };

      const setIntervals = () => {
        clearInterval(intervalList.value);
        intervalList.value = setInterval(getList, 5000);
      };
    };
    const onPage = (event: any) => {
      if (event.page == state.currentPage + 1) return;
      getHistory(event.page + 1);
    };

    const getHistory = (page: number) => {
      if (!IsLegginged()) return;
      const market = store.state?.selectedSymbol?.spot
        ? store.state.selectedSymbol?.spot?.asset + "USDT"
        : "BTCUSDT";

      TradeRepository.getInstance().GetSpotHistory({
        query: `${state.getAllSymbol ? "" : market}${
          state.activeTab == 4 ? "&cancled=true" : ""
        }&page=${page}&limit=10`,
        onReceive: (res) => {
          if (res?.list) {
            state.history.list = res.list;
          } else {
            state.history.list = [...res];
          }
          if (res?.count) state.total = res.count;
        },
        onLoadChange: (load) => (state.history.loading = load),
      });
    };

    const onExport = () => {
      switch (state.activeTab) {
        case 1:
          const token = localStorage.getItem("token");
          if (!!!token || state.activeTab != 1) return null;
          TradeRepository.getInstance().GetPendingOrders({
            query: !state.getAllSymbol
              ? store.state?.selectedSymbol && store.state.selectedSymbol?.spot
                ? store.state.selectedSymbol?.spot?.asset
                : "BTCUSDT"
              : undefined,
            onReceive: (res) => {
              console.log("====================================");
              console.log(res);
              console.log("====================================");
              const _list = res.map((item) => {
                const _item = {
                  Time: DataFormatService.getInstance().convertToMiladi(
                    item.createdAt
                  ),
                  "all Pairs": item.market.toUpperCase(),
                  "all Types": item.type.toUpperCase(),
                  "buy / sell": item.isbuy ? "Buy" : "Sell",
                  price: item.price,
                  avgprice: item.avgprice,
                  amount: item.amount,
                  filled: item.processamount,
                };

                return _item;
              });
              exportExel("History", _list);
            },
            onLoadChange: (load) => (state.exportLoading = load),
          });
          break;

        default:
          if (!IsLegginged()) return;
          const market = store.state?.selectedSymbol?.spot
            ? store.state.selectedSymbol?.spot?.asset + "USDT"
            : "BTCUSDT";

          TradeRepository.getInstance().GetSpotHistory({
            query: `${state.getAllSymbol ? "" : market}${
              state.activeTab == 4 ? "&cancled=true" : ""
            }&page=1&limit=10000`,
            onReceive: (res) => {
              const _list = res?.list.map((item) => {
                const _item =
                  state.activeTab == 3
                    ? {
                        Time: DataFormatService.getInstance().convertToMiladi(
                          item.createdAt
                        ),
                        "all Pairs": item.market.toUpperCase(),
                        "all Types": item.type.toUpperCase(),
                        "buy / sell": item.isbuy ? "Buy" : "Sell",
                        price: item.price,
                        avgprice: item.avgprice,
                        amount: item.amount,
                        filled: item.processamount,
                        all: item.canceled
                          ? "Canceled"
                          : item.completed
                          ? "Completed"
                          : "Pending",
                      }
                    : {
                        Time: new Date(item.createdAt).toLocaleString(),
                        "all Pairs": item.market.toUpperCase(),
                        "all Types": item.type.toUpperCase(),
                        "buy / sell": item.isbuy ? "Buy" : "Sell",
                        price: item.price,
                        avgprice: item.avgprice,
                        amount: item.amount,
                        volume: item.volume,
                        filled: item.processamount,
                        fee: `${Number(item.fee)} USDT`,
                      };

                return _item;
              });
              exportExel("History", _list);
            },
            onLoadChange: (load) => (state.exportLoading = load),
          });
          break;
      }
    };

    onMounted(() => {
      reGetList();
    });

    const reGetList = () => {
      onChangeMarket();
      getHistory(1);
      intervalList.value = setInterval(getList, 5000);
    };

    onBeforeUnmount(() => {
      clearInterval(intervalList.value);
    });

    return {
      onCancelSingleOrder,
      cancelAllOrder,
      ...toRefs(state),
      convertToIso,
      confirmDelete,
      reGetList,
      getHistory,
      onPage,
      onExport,
    };
  },
};
