import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ef5753a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column"
}
const _hoisted_2 = { class: "f-15" }
const _hoisted_3 = { class: "f-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!$props.loadingProfile)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (!!_ctx.$store.state.userDetails)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_Button, {
                  iconPos: "right",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/assets/overview'))),
                  class: "p-button-sm btn-register smart-text-align mt-2"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_2, _toDisplayString(`${_ctx.$t('overview')} ${_ctx.$t('assets')}`), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Button, {
                  iconPos: "right",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/assets/spotList'))),
                  class: "p-button-sm btn-register smart-text-align mt-2"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('spot')), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}