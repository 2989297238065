
import { onMounted, reactive, toRefs } from "vue";
import store from "@/core/store";
import { ToastService } from "@/core/service/toast.service";
import { i18n } from "@/main";
import { TradeRepository } from "@/core/repository/trade.repository";
import BalanceDetailsComponent from "../shared/trade/BalanceDetailsComponent.vue";
import { mapState } from "vuex";
import { mapState as PMapState } from "pinia";
import NoAccessComponent from "../shared/noAccess/NoAccessComponent.vue";
import { profileStore } from "@/core/store/profile.store";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
export default {
  computed: {
    ...mapState({
      userDetails: "userDetails",
    }),
    ...PMapState(profileStore, ["isBuy"]),
  },
  setup() {
    const mainState = reactive({
      buy: {
        volume: 0,
        price: null,
        amount: null,
        stopprice: null,
        loading: false,
      },
      sell: {
        volume: 0,
        price: null,
        amount: null,
        stopprice: null,
        loading: false,
      },
      selectedType: 0,
      types: [
        { label: i18n.global.tc("Limit"), key: 0 },
        { label: i18n.global.tc("market"), key: 1 },
      ],
      tradeTypes: [
        { label: "Buy", value: true },
        { label: "Sell", value: false },
      ],
      getHistory: false,
      maxDismal: 3,
    });

    const pStore = profileStore();
    const errorToast = (msg) => {
      ToastService.getInstance().error(msg);
    };

    const createOrder = (isBuy = true, values) => {
      pStore.isBuy = isBuy;
      const defaultBody = {
        market: store.state.selectedSymbol?.spot?.asset + "USDT",
      };

      let body;
      let key;
      switch (mainState.selectedType) {
        case 0:
          key = isBuy ? "CreateLimitOrderBuy" : "CreateLimitOrderSell";
          if (Number(values.amount) <= 0 || Number(values.price) <= 0)
            return errorToast(i18n.global.tc("priceAndAmountIsRequired"));
          body = {
            price: values.price,
            amount: values.amount,
            ...defaultBody,
          };
          break;
        case 1:
          key = isBuy ? "CreateBuyMarketOrder" : "CreateSellMarketOrder";
          if (Number(values.amount) <= 0 || Number(values.price) <= 0)
            return errorToast(i18n.global.tc("priceAndAmountIsRequired"));
          body = {
            amount: values.amount,
            ...defaultBody,
          };
          break;
      }

      TradeRepository.getInstance()[key]({
        body: body,
        onReceive: (res) => {
          ToastService.getInstance().success(i18n.global.tc("Success"));
          store.dispatch("clearFields", 1);
          store.commit("updateHistoryList", {
            data: res,
          });
          profileStore().getUserSpotBalance();
        },
        onLoadChange: (load) =>
          (mainState[isBuy ? "buy" : "sell"].loading = load),
      });
    };

    const onChangeTab = (key) => {
      switch (key) {
        case 0:
        case 3:
          store.commit("changeTvHeight", "27.4rem");
          break;
        case 1:
          store.commit("changeTvHeight", "27.4rem");
          break;
        case 2:
          store.commit("changeTvHeight", "27.4rem");
          break;
      }

      mainState.selectedType = key;
    };

    onMounted(() => {
      let tradeDetail;
      store.subscribe((mutation, state) => {
        if (tradeDetail !== state.tradeDetails) {
          tradeDetail = state.tradeDetails;
          mainState.sell.price = state.tradeDetails.volume;
          mainState.buy.price = state.tradeDetails.volume;
        }
        if (mutation.type == "onChangeSelectedSymbol") {
          store.dispatch("clearFields", 1);
        }

        if (mutation.type == "setOrderBook") {
          mainState.maxDismal =
            store.state?.orderBooks &&
            store.state.orderBooks?.ask &&
            store.state.orderBooks?.ask[0]
              ? store.state.orderBooks?.ask[0][0].split(".")[1]?.length
              : 3;
        }
      });
    });

    return {
      ...toRefs(mainState),
      onChangeTab,
      createOrder,
      pStore,
    };
  },
  components: { BalanceDetailsComponent, NoAccessComponent, TabPanel, TabView },
};
