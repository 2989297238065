import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bd10c16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container d-none d-lg-block py-5" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-3" }
const _hoisted_4 = { class: "side" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "cp" }
const _hoisted_9 = { class: "col-9" }
const _hoisted_10 = { class: "mobile-controller d-lg-none py-4 px-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogCoinListComponent = _resolveComponent("DialogCoinListComponent")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransferComponent = _resolveComponent("TransferComponent")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dialog, {
      showHeader: false,
      visible: _ctx.assetsDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.assetsDialog) = $event)),
      dismissableMask: "",
      class: "mx-auto text-center",
      containerStyle: { width: '98%', maxWidth: '312px' },
      modal: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DialogCoinListComponent, {
          list: _ctx.$store.state.assets.list,
          loading: _ctx.$store.state.assets.loading,
          onOnSelect: _cache[0] || (_cache[0] = (data) => $setup.onSelectCoin(data)),
          onOnHide: _cache[1] || (_cache[1] = () => (_ctx.assetsDialog = false))
        }, null, 8, ["list", "loading"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      showHeader: false,
      visible: _ctx.transferDialog,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.transferDialog) = $event)),
      dismissableMask: "",
      class: "mx-auto text-center",
      containerStyle: { width: '98%', maxWidth: '312px' },
      modal: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TransferComponent)
      ]),
      _: 1
    }, 8, ["visible"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Accordion, {
            activeIndex: _ctx.active,
            "onUpdate:activeIndex": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.active) = $event))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sideList, (item) => {
                return (_openBlock(), _createBlock(_component_AccordionTab, {
                  key: item.label
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["item", _ctx.activeSide == item.path && 'active']),
                        onClick: ($event: any) => ((_ctx.activeSide = item.path), _ctx.$router.push(item.to))
                      }, [
                        _createElementVNode("img", {
                          class: "icon",
                          src: require(`@/asset/img/icons/${item.icon}.png`),
                          alt: ""
                        }, null, 8, _hoisted_6),
                        _createElementVNode("img", {
                          class: "icon hover",
                          src: require(`@/asset/img/icons/${item.icon}-hover.png`),
                          alt: ""
                        }, null, 8, _hoisted_7),
                        _createElementVNode("span", _hoisted_8, _toDisplayString(item.label), 1)
                      ], 10, _hoisted_5)
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["activeIndex"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_router_view, {
            sideList: _ctx.sideList,
            options: _ctx.options,
            onInitTransfer: _cache[5] || (_cache[5] = (key) => $setup.initTransfer(key))
          }, null, 8, ["sideList", "options"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_router_view, {
        sideList: _ctx.sideList,
        options: _ctx.options,
        onInitTransfer: _cache[6] || (_cache[6] = (key) => $setup.initTransfer(key))
      }, null, 8, ["sideList", "options"])
    ])
  ]))
}