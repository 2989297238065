export class WebSocketService {
  connection:WebSocket ;
  url:string ;
  first = true;
  close = false;
  constructor(url:string){ 
    this.url = url;
    this.close = false;

  }

  checkJson(text) :boolean {
    if (/^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').
    replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
    replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
      return true
    }else{
      return false
    }
  }

  getMainList(wsData: any) {

    var interval;
    const tryConnect = async () => {
      console.log('try ' , this.url);
      this.connection = new WebSocket(this.url);
      this.connection.onopen = (e) => {
        cancelTry()
      }

      this.connection.onmessage = (event: any) => {
    
        if (event && event.data && !this.close) {
          if (this.checkJson(String(event.data))) {
            const data = JSON.parse(event.data);
            if (!data || Object.keys(data).length === 0) return
            wsData(data)
          }
        }
      }

    }
    if (this.first) {
      tryConnect();
      this.first = false;
    }

    const cancelTry = () => {
      console.log('connected !');
      clearInterval(interval);
    }

    this.connection.onerror = (event) => {
      console.log('error coonnection');
      this.connection.close();
      clearInterval(interval);
      interval = setInterval(tryConnect, 5000);
    }

  }

  closeConnection(){
    console.log('close' , this.url);
    this.connection.close();
    this.close = true;

  }
  
}

