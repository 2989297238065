
import { onMounted, reactive, ref, toRefs } from 'vue'
import store from '@/core/store';
import { DataFormatService } from '@/core/service/dataFormat.service';
import CoinListComponent from "@/app/unit/shared/coins/coinListComponent.vue";
// import TrendySwapComponent from './trendySwapComponent.vue';
import { ToastService } from '@/core/service/toast.service';
import SwapHistoryComponent from './SwapHistoryComponent.vue';
import { SwapRepository } from '@/core/repository/swap.repository';
import { mapState } from 'vuex';
import { mapState as piniaMapState } from 'pinia';
import { GRemoveSuffix, Translate } from '@/core/service/utils.service';
import { profileStore } from '@/core/store/profile.store';
import { i18n } from '@/main';

export default {
    components:{
        CoinListComponent,
        SwapHistoryComponent
    },
    computed: {
        getBalance(){
          return (symbol:string) => {
            const  {obj} = profileStore().spotBalance;
            return obj?.has(symbol.toLowerCase()) ? obj.get(symbol.toLowerCase()) : 0
            }
        },
        ...piniaMapState(profileStore , ['spotBalance']),
        ...mapState({
            userDetails:(state:any)=> state.userDetails,
            wsAssets:(state:any)=> state.wsAssets
        }),
    },
    setup () {
        const state = reactive({
            from:{
                selected:null,
                value:null
            },
            to:{
                selected:null,
                value:null
            },
            loading:false,
            assetsList:{}
        });
        const ProfileStore = profileStore()
        const coinsPanelFrom = ref();
        const coinsPanelTo = ref();

        const showFromCoins = (event) => (state.from.selected) && coinsPanelFrom.value.toggle(event);
        const showToCoins = (event) => (state.to.selected) && coinsPanelTo.value.toggle(event);

        const onChangePrice = (val,isFrom = true) => {
            
            const data = store.state.wsAssets.data;
            if (!data || val <= 0) return
            
            if (isFrom) {
            const TPrice = Number(data[state.to.selected].price_usd);

                state.to.value = null;
                const value = val *  Number(data[state.from.selected].price_usd)
                state.to.value = Number(Number(value / TPrice).toFixed(8))
            } else {
            const TPrice = Number(data[state.from.selected].price_usd);
                state.from.value = null;
                const value = val *  Number(data[state.to.selected].price_usd)
                state.from.value = Number(Number(value / TPrice).toFixed(8))
            }
        }

        const getBalance = (symbol:string) => {
            const  {obj} = profileStore().spotBalance;
            return obj?.has(symbol.toLowerCase()) ? obj.get(symbol.toLowerCase()) : 0
        }

        const onSubmit = () => {
            //check available
            const selectedSource = state.from.selected ;
            const available = getBalance(selectedSource);
            const amount = state.from.value;

            if (available <  amount) return ToastService.getInstance().error(Translate('balanceIsNotEnough'));
            if (amount <= 0) return ToastService.getInstance().error(i18n.global.tc('isRequired' , {name:Translate('amount')}))
            //call api
            SwapRepository.getInstance().Swap({
                body:{
                    to:state.to.selected,
                    from:state.from.selected,
                    amount
                },onReceive:(res)=> {
                    ToastService.getInstance().success(res.msg)
                    state.from.value = null;
                    state.to.value = null;
                    profileStore().getUserSpotBalance();
                    getList();
                },onLoadChange:(load)=> state.loading = load
            })
        };

        onMounted(() => {
            profileStore().getUserSpotBalance();
            getList();
        });

        const getList = () => {
            if (!store.state.wsAssets.data) {

                const unsubscribe = store.subscribe((mutation, stat) => {
    
                    if (mutation.type == 'setMarketPrice' ) {
                        const interval = setInterval(checkList, 1000);
    
                        function checkList () {
                            if (stat.wsAssets.data ){
                                
                                if (!state.from.selected) {
                                    const data = stat.wsAssets.data;
                                    state.from.selected =  data['TRX'].asset;
                                    state.to.selected = data['GST'].asset;
                                    removeSub();
                                    stopInterval();
                                } else {
                                    removeSub();
                                    stopInterval();
                                }
                            }
                        }
    
                        const stopInterval = () => {
                            clearInterval(interval);
                        }  
    
                    }
                })
    
                const removeSub = () => {
                    unsubscribe();
                }
            } else {
                if (!state.from.selected) {
                    const data = store.state.wsAssets.data;
                    state.from.selected =  data['TRX'].asset;
                    state.to.selected = data['GST'].asset;
                }
            } 

        };

        const getReferencePrice = () => {
            if (store.state.wsAssets.data ) {
                const data = store.state.wsAssets.data;
                const FPrice = Number(data[state.from.selected].price_usd);
                const TPrice = Number(data[state.to.selected].price_usd);

                return DataFormatService.getInstance().currency(Number(TPrice / FPrice).toFixed(8))
            } else return '....'
        };

        const switchCoin = () => {
            state.from.value = null;
            state.to.value = null;
            
            const data = {
                from:{...state.from},
                to:{...state.to}
            };

            state.to = data.from;
            state.from = data.to;
        };
        
        const setMax = () => {
            const {spotBalance} = ProfileStore;

             if (spotBalance.obj.has(state.from.selected.toLowerCase())) {
                state.from.value = spotBalance.obj.get(state.from.selected.toLowerCase());
                onChangePrice(state.from.value)
            }
        }

        return {
            ...toRefs(state),
            showFromCoins,
            getReferencePrice,
            showToCoins,
            coinsPanelFrom,
            coinsPanelTo,
            switchCoin,
            onSubmit,
            onChangePrice,
            setMax,
            GRemoveSuffix
        }
    }
}
