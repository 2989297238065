
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex';
import {mapState} from 'pinia';
import QRCodeVue3 from "qrcode-vue3";
import { ToastService } from '@/core/service/toast.service';
import { i18n } from '@/main';
import VOtpInput from 'vue3-otp-input';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import TipsComponent from "../../../shared/tips/tipsComponent.vue";
import { KycRepository } from '@/core/repository/kyc.repository';
import CountriesList from '@/app/unit/components/CountriesList.vue';
import Countdown from 'vue3-countdown';
import { profileStore } from '@/core/store/profile.store';
import { AccountRepository } from '@/core/repository/account.repository';

export default {
    components:{
        QRCodeVue3,
        VOtpInput,
        Accordion,
        AccordionTab,
        TipsComponent,
        CountriesList,
        Countdown
    },
    computed:{
      ...mapState(profileStore , ['unBindToTpDialog'])
    },
    setup () {
        const state = reactive({
           code:''
        })

        const store = useStore();

        const onCloseDialog = () => {
            profileStore().unBindToTpDialog = false;
        }

        const handleOnChange = (value: string) => {
            state.code = value;
        };
        const handleOnComplete = (value: string) => {
            state.code = value;
            UnBindToTp()
        };

        const UnBindToTp = () => {
            const {code} = state;
            AccountRepository.getInstance().UnBindToTp({
                body:{code},
                onReceive:(res)=>{
                    store.commit('getUserDetails');
                    ToastService.getInstance().success(i18n.global.tc('Success'));
                    onCloseDialog();
                    profileStore().unBindToTpDialog = false;
                    console.log(res);
                },
            })
        }
       
        return {
            ...toRefs(state),
            onCloseDialog,
            handleOnChange,
            UnBindToTp,
            handleOnComplete
        }
    }
}
