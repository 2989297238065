
import { onMounted, reactive, toRefs, watch } from "vue";
import store from "@/core/store";
import { Constant } from "@/core/constant";
import { useRouter } from "vue-router";
export default {
  setup() {
    const state = reactive({
      count: 0,
      search: "",
      marketList: [] as any[],
    });
    const router = useRouter();
    const onErrorImage = (e) => {
      e.target.src = Constant.ErrorImageUrl;
    };

    const onSelectCoin = (data) => {
      store.commit("onChangeSelectedSymbol", {
        data: data,
      });
      router.push("/spotTrade");
    };

    onMounted(() => {
      state.marketList = store.state.wsAssets.marketList;
    });

    watch(
      () => store.state.wsAssets.marketList,
      () => {
        if (state.search === "")
          state.marketList = store.state.wsAssets.marketList;
      }
    );

    const searchCoin = () => {
      if (state.search !== "") {
        const filterCoins = store.state.wsAssets.marketList.filter(
          (item: any) => item.asset.includes(state.search.toUpperCase())
        );

        state.marketList = filterCoins;
      } else {
        state.marketList = store.state.wsAssets.marketList;
      }
    };

    return {
      onErrorImage,
      ...toRefs(state),
      onSelectCoin,
      searchCoin,
    };
  },
};
