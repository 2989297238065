
import { mapState, useStore } from "vuex";
import { GRemoveSuffix, USDTORUSDC } from "@/core/service/utils.service";
import { computed, onMounted, reactive, toRefs, watch } from "vue";
import store from "@/core/store";
import { i18n } from "@/main";
import { ToastService } from "@/core/service/toast.service";
import { mapState as PiniaMapState, mapActions } from "pinia";
import { profileStore } from "@/core/store/profile.store";
export default {
  props: ["isBuy", "selectedType", "data", "isMobile", "maxDismal", "loading"],
  computed: {
    ...mapState({
      wsAssets: "wsAssets",
      selectedSymbol: "selectedSymbol",
    }),
    ...PiniaMapState(profileStore, ["spotBalance"]),
    getBalance() {
      const { spotBalance } = profileStore();
      return (isSuffix = true) => {
        const symbol = store.state.selectedSymbol?.spot?.asset;
        const name = String(
          isSuffix
            ? GRemoveSuffix(symbol + "USDT")
            : USDTORUSDC(symbol + "USDT")
        ).toLowerCase();
        return spotBalance.obj.has(name)
          ? Number(spotBalance.obj.get(name).toFixed(6))
          : 0;
      };
    },
    getUsdPrice() {
      return store.state.wsAssets.data[
        GRemoveSuffix(store.state.selectedSymbol?.spot?.asset)
      ].price_usd;
    },
  },
  emits: ["createOrder"],
  setup(props, { emit }) {
    const state = reactive({
      count: 0,
      marks: [0, 25, 50, 75, 100],
      price: 0,
      amount: 0,
      volume: 0,
      stopprice: null,
      maxSize: null,
    });

    const store = useStore();
    const getBalance = (isSuffix = true) => {
      const { spotBalance } = profileStore();
      const symbol = store.state.selectedSymbol?.spot?.asset;
      const name = String(
        isSuffix ? GRemoveSuffix(symbol + "USDT") : USDTORUSDC(symbol + "USDT")
      ).toLowerCase();
      return spotBalance.obj.has(name) ? spotBalance.obj.get(name) : 0;
    };

    const getUsdPrice = () =>
      store.state.wsAssets.data[
        GRemoveSuffix(store.state.selectedSymbol?.spot?.asset)
      ].price_usd;
    const calc = (fromSymbol, value) => {
      const symbolPrice = Number(getUsdPrice());
      const price =
        props.selectedType == 1 || props.selectedType == 3
          ? symbolPrice
          : state.price;
      const balance = props.isBuy ? getBalance(false) : Number(getBalance());

      if (value > 0 && price > 0 && balance) {
        const val = props.isBuy
          ? ((value * price) / balance) * 100
          : (value * 100) / balance;
        if (val >= 100) {
          state.volume = 100;
          calcSlider(100);
        } else {
          state.volume = Math.round(val);
        }
      } else {
        ToastService.getInstance().error(
          i18n.global.tc("isRequired", { name: i18n.global.tc("price") })
        );
        clearForm(3);
      }
    };

    const calcSlider = (value) => {
      // const priceMarket =  state.price;
      const price = Number(
        props.selectedType == 1 || props.selectedType == 3
          ? Number(getUsdPrice())
          : state.price
      );
      if (Number(price) > 0 && value > 0) {
        if (props.isBuy) {
          const balanceUSDT = Number(getBalance(false));
          const PriceusedUSDT = (balanceUSDT * value) / 100;

          const AmountOfCrypto = PriceusedUSDT / price;
          if (balanceUSDT) state.amount = AmountOfCrypto;
          else clearForm(3);
          if (value == 100) state.maxSize = AmountOfCrypto;
        } else {
          ///SELL
          const BalanceCrypto = Number(getBalance());

          const AmountToSell = (BalanceCrypto * value) / 100;
          // const AmountGiveUSDT = AmountToSell*price ;
          if (BalanceCrypto) state.amount = AmountToSell;
          else clearForm(3);
          if (value == 100) state.maxSize = AmountToSell;
        }
      } else {
        clearForm(3);
      }
    };

    const onCreateOrder = () => {
      const balance = props.isBuy
        ? Number(getBalance(false))
        : Number(getBalance());
      const symbolPrice = Number(
        props.selectedType == 1 || props.selectedType == 3
          ? Number(getUsdPrice())
          : state.price
      );
      let amount = props.isBuy
        ? state.amount * symbolPrice
        : state.amount * symbolPrice;

      if (
        state.volume !== 100 &&
        Number(balance.toFixed(4)) < Number(amount.toFixed(4))
      )
        return ToastService.getInstance().error(
          i18n.global.tc("balanceIsNotEnough")
        );
      const price =
        props.selectedType == 1 || props.selectedType == 3
          ? symbolPrice
          : state.price;

      if ((props.selectedType == 1 || props.selectedType == 3) && props.isBuy) {
        amount = Number(getBalance(false) * state.volume) / 100;
      }

      emit("createOrder", {
        price: price,
        amount:
          (props.selectedType == 1 || props.selectedType == 3) && props.isBuy
            ? amount
            : state.amount,
        stopprice: state.stopprice,
      });
    };

    onMounted(() => {
      watchClear();
    });

    const watchClear = () => {
      store.subscribeAction((action, state) => {
        if (action.type == "clearFields") {
          clearForm(action.payload);
        } else if (action.type == "setTradeVolume") {
          setPrice(action.payload.volume);
        }
      });

      const setPrice = (val) => {
        state.price = val;
      };
    };

    const clearForm = (key) => {
      state.amount = 0;
      setTimeout(() => {
        state.volume = 0;
      }, 500);
      state.stopprice = 0;
      if (key == 1) {
        state.price = 0;
      }
    };
    const selectedOrderBookPrice = computed(
      () => store.state.selectedOrderBookPrice
    );
    watch(selectedOrderBookPrice, () => {
      state.price = selectedOrderBookPrice.value;
    });

    watch(
      () => props.data,
      (first) => {
        if (first) {
          state.price = Number(first);
        }
      }
    );
    const getUserSpotBalance = () => profileStore().getUserSpotBalance();
    return {
      GRemoveSuffix,
      ...toRefs(state),
      calc,
      calcSlider,
      onCreateOrder,
      USDTORUSDC,
      getUserSpotBalance,
    };
  },
};
