

import { DateService } from "./date.service";
import moment from "moment";

export class DataFormatService {
  private static instance: DataFormatService

  public static getInstance(): DataFormatService {
    if (!DataFormatService.instance) DataFormatService.instance = new DataFormatService()
    return DataFormatService.instance
  }

  currency(value: any) {
    const max = true;
    const isUsdt = true;

    let _value = 0;
    const scientificPattern = /^[0-9]+\.?[0-9]*e[+\-]?[0-9]+$/;
    if (scientificPattern.test(Number(value).toString())) {
        const _val = Number(value).toFixed(8)
        _value = Number(_val);
        return _val
    } else {
      _value = value;
    }

      if (Number(_value) > 999) {
        if (max) {
          return Intl.NumberFormat().format(Number(Number(_value).toFixed(5)));
        } else {
          const _val = isUsdt ? Number(Math.floor(Number(_value)  * 100) / 100) :  Math.trunc(Number(_value));
          return Intl.NumberFormat().format((_val));
        }
      } else {
        if (String(_value).length >= 3) {
          if (!max) {
            const _val = isUsdt ? Number(Math.floor(Number(_value)  * 100) / 100) : (Number(_value));
            return this.eToNumber((_val))
          } else   return this.eToNumber(Number(Number(_value).toFixed(8)))
        } else return _value;
      }

  }

   eToNumber(num) {
    try {
      
      let sign = "";
      (num += "").charAt(0) == "-" && (num = num.substring(1), sign = "-");
      let arr = num.split(/[e]/ig);
      if (arr.length < 2) return sign + num;
      let dot = (.1).toLocaleString().substr(1, 1), n = arr[0], exp = +arr[1],
          w = (n = n.replace(/^0+/, '')).replace(dot, ''),
        pos = n.split(dot)[1] ? n.indexOf(dot) + exp : w.length + exp,
        L   = pos - w.length, s = "" + BigInt(w);
        w   = exp >= 0 ? (L >= 0 ? s + "0".repeat(L) : r()) : (pos <= 0 ? "0" + dot + "0".repeat(Math.abs(pos)) + s : r());
      L= w.split(dot); if (L[0]==0 && L[1]==0 || (+w==0 && +s==0) ) w = 0; //** added 9/10/2021
      return sign + w;
      function r() {return w.replace(new RegExp(`^(.{${pos}})(.)`), `$1${dot}$2`)}
    } catch (error) {
      return num
    }
  }


  convertToDate(value: any) {
    const newDate = value.replace('T', ' ');
    return DateService.getInstance().convertToJalali(newDate);
  }


  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  convertToMiladi(value:string) {
    try {
      const date = new Date(value)
  const _date = (date).toISOString().split('T')[0]+ ' ' + (date).toISOString().split('T')[1].split('.')[0];

  const utc = moment.utc(_date , 'YYYY/MM/DD  HH:mm'); 
  return utc.local().format('YYYY/MM/DD  HH:mm')
    } catch (error) {
      return null
    }
  }

  roundNumber(value) {
    
    if (Number(value) > 999 && Number(value) < 999999) {
      return `${Math.round(Number(value) / 1000)} K`
    } else if (Number(value) > 999999 && Number(value) < 999999999) {
      return `${Math.round(Number(value) / 1000000)} M`
    } else if (Number(value) > 999999999) {
      return `${Math.round(Number(value) / 1000000000)} T`
    } else {
      return (value)
    }

    
  }

  checkStrongPassword(pass:string) : boolean {
    const regex = RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
    return regex.test(pass);
  }
}
