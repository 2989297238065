
import { onMounted, reactive, toRefs, watch } from "vue";
import EmptyBox from "@/app/unit/shared/emptyBox/emptyBox.vue";
import { Constant } from "@/core/constant";
import store from "@/core/store";
import Checkbox from "primevue/checkbox";
import { TradeRepository } from "@/core/repository/trade.repository";
import { FilterMatchMode } from "primevue/api";
import router from "@/core/router/router";
import { MyStakeModel, StakeListModel } from "@/data/assets.model";
import TransferComponent from "@/app/unit/components/TransferComponent.vue";
import moment from "moment";

export default {
  emits: ["initTransfer"],
  components: { EmptyBox, Checkbox, TransferComponent },
  computed: {
    // isBlock() {
    //     return (date:Date) =>
    // }
  },
  setup(props) {
    const state = reactive({
      filter: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      showPrice: true,
      hideSmall: false,
      transferDialog: false,
      balance: {
        data: new Map<string, number>([]),
        loading: false,
        total: 0,
      },
      assets: {
        list: [] as MyStakeModel[],
        loading: false,
      },
    });

    const onErrorImage = (e) => {
      e.target.src = Constant.ErrorImageUrl;
    };

    const onSelectCoin = (data, key) => {
      const selected = store.state.assets.list.find(
        (item) => item.asset == data
      );

      const payload = {
        data: selected,
        isAssets: true,
      };

      // store.commit('onChangeSelectedSymbol' , {...payload});
      setTimeout(() => {
        switch (key) {
          case 1: //chart
            break;
        }
      }, 1000);
    };

    const getStakeBalance = () => {
      state.assets.loading = true;
      TradeRepository.getInstance().GetStakeAssets({
        onReceive: (stakeRes: StakeListModel[]) => {
          console.log(stakeRes);
          TradeRepository.getInstance().GetMyStakes({
            onReceive: (res: MyStakeModel[]) => {
              console.log("res", res);

              state.assets.list = res;
              state.assets.list.map((item) => {
                item.blockDay = stakeRes.find(
                  (finder) =>
                    finder.symbol.toLowerCase() == item.symbol.toLowerCase()
                ).dayblocked;
                return item;
              });

              state.balance.loading = true;
              new Promise(async (resolve, reject) => {
                const data = await store.state.wsAssets.data;
                if (data) {
                  state.balance.loading = false;
                  state.balance.total = 0;
                  let balance = new Map<string, number>([]);

                  res.forEach((item) => {
                    state.balance.total +=
                      Number(data[item.symbol.toUpperCase()].price_usd) *
                      Number(item.balance);
                    balance.set(item.symbol, item.balance);
                  });

                  state.balance.data = balance;
                }
              });
            },
            onLoadChange: (load) => (state.assets.loading = load),
          });
        },
      });
    };

    const initData = () => {
      state.transferDialog = false;
      getStakeBalance();
    };

    const watchBalance = () => {
      store.subscribe((mutation, state) => {
        if (
          mutation.type == "setMarketPrice" ||
          mutation.type == "updateBalance"
        ) {
          initData();
        }
      });
    };
    const addDays = (day: number, oldDate: Date) => {
      console.log("oldDate", oldDate);

      let date = new Date(oldDate);
      // date.setTime(date.getTime() +  (day * 24 * 60 * 60 * 1000))
      return moment(date).add({ days: day }).format("YYYY/MM/DD  h:mm:ss");
    };
    onMounted(() => {
      initData();
      watchBalance();
    });

    return {
      ...toRefs(state),
      onErrorImage,
      initData,
      onSelectCoin,
      addDays,
    };
  },
};
