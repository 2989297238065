import { Constant } from "../constant";
import { Repository, ReturnResponse } from "./repository";

export class HomeRepository extends Repository {
  private static instance: HomeRepository;
  public baseUrl = Constant.BASE_URL;

  public static getInstance(): HomeRepository {
    if (!HomeRepository.instance)
      HomeRepository.instance = new HomeRepository();
    return HomeRepository.instance;
  }

  public GetHomeData(data: ReturnResponse) {
    data.url = this.baseUrl + "spot/website";
    this.get(data);
  }
}
