import {LocalStorageService} from "@/core/service/localStorage.service";
import {ref} from "vue";
import {EnumKeys, LanguageType} from "../enum";
import store from './../store/index';
import { i18n } from './../../main';

export const WindowLoading = ref(false);
export const isLoginCheck = ref(false);

export const isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
}

export function changeAppDirection(locale) {
    console.log('locale'  , locale);
    
    document.body.dir = (locale == LanguageType.fa || locale == LanguageType.ar)  ? 'rtl' : 'ltr';
    // document.body.dir = 'ltr
    
    console.log('document.body.dir'  , document.body.dir);
    LocalStorageService.getInstance().setItem(EnumKeys.KeyLanguage, locale)
}

export function changeTheme(darkMode){
    let htmlElement = document.documentElement;

    if (darkMode) {
        localStorage.setItem("theme", 'dark');
        htmlElement.setAttribute('theme', 'dark');
    } else {
        localStorage.setItem("theme", 'light');
        htmlElement.setAttribute('theme', 'light');
    }
}

export function getLanguageFromCache() {
    let lang = LocalStorageService.getInstance().getItem(EnumKeys.KeyLanguage);
    if (lang == LanguageType.en) {
        return LanguageType.en;
    } else if (lang == LanguageType.fa) {
        return LanguageType.fa;
    }else if (lang == LanguageType.tr) {
        return LanguageType.tr;
    }else if (lang == LanguageType.ar) {
        return LanguageType.ar;
    } else {
        LocalStorageService.getInstance().setItem(EnumKeys.KeyLanguage, LanguageType.en)
        return LanguageType.en;
    }
}

export const IsLegginged = () => {
    const token = localStorage.getItem('token');
    return !!token;
}

export function GenerateString(length) {
    const result = [];
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
}

export function GRemoveSuffix (val:string) {
    if (!val) return val
    if (val == 'USDTUSDC' || val == 'USDT') return 'USDT' 
    if (val == 'USDCUSDT' || val == 'USDC') return 'USDC' 
    return val.replace('USDT' , '').replace('USDC' , '');
}


export function USDTORUSDC (val:string) {
    const str = val.includes('USDT') ? 'USDT' : val.includes('USDC') ? 'USDC' : val;
    return str 
}

export function Translate (val:string) {
    return i18n.global.tc(val)
}