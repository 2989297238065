
import { onMounted, reactive, toRefs } from "vue";
import EmptyBox from "../../shared/emptyBox/emptyBox.vue";
import { IsLegginged } from "@/core/service/utils.service";
import Paginator from "primevue/paginator";
import { SwapRepository } from "@/core/repository/swap.repository";
import { Constant } from "@/core/constant";
import { DataFormatService } from "@/core/service/dataFormat.service";
import { exportExel } from "@/core/service/exportExel";

interface ListInterface {
  _id: string;
  user: string;
  from: string;
  to: string;
  amount: number;
  toamount: number;
  dealmoney: number;
  id1: string;
  price1: number;
  id2: string;
  price2: number;
  date: string;
  __v: number;
}

export default {
  setup() {
    const state = reactive({
      list: [] as ListInterface[],
      page: 1,
      loading: false,
      total: 0,
      exportLoading: false,
      filterModal: false,
      fromDate: "",
      toDate: "",
    });
    // tagret / sorce =
    // 1 btc = 20.000 ustd

    const getData = (page: number) => {
      state.page = page;
      const from = new Date(state.fromDate).getTime();
      const to = new Date(state.toDate).getTime();

      if (!IsLegginged()) return;
      SwapRepository.getInstance().GetSwapHistory({
        urlContent: {
          url: `page=${page}&limit=10${state.fromDate ? `&from=${from}` : ""}${
            state.toDate ? `&to=${to}` : ""
          }`,
        },
        onReceive: (res) => {
          state.total = res.count;
          state.list = [...res.list];

          state.filterModal = false;
        },
        onLoadChange: (load) => (state.loading = load),
      });
    };

    onMounted(() => {
      getData(1);
    });

    const onErrorImage = (e) => {
      e.target.src = Constant.ErrorImageUrl;
    };

    const onExport = () => {
      console.log(state);

      const from = new Date(state.fromDate).getTime();
      const to = new Date(state.toDate).getTime();
      SwapRepository.getInstance().GetSwapHistory({
        urlContent: {
          url: `page=${state.page}&limit=10000${
            state.fromDate ? `&from=${from}` : ""
          }${state.toDate ? `&to=${to}` : ""}`,
        },
        onReceive: (res) => {
          if (res.list && res.count) {
            const list = [...res.list];
            const _list = list.map((item) => {
              const _item = {
                Time: item.date,
                From: item.from,
                To: item.to,
                FromAmount: item.amount,
                ToAmount: item.toamount,
                FromPrice: item.price1,
                ToPrice: item.price2,
                DealMoney: item.dealmoney,
              };

              return _item;
            });
            exportExel("Swap", _list);
          }
        },
        onLoadChange: (load) => (state.exportLoading = load),
      });
    };

    const openFilterModal = () => {
      state.filterModal = true;
      state.fromDate = "";
      state.toDate = "";
    };

    return {
      ...toRefs(state),
      getData,
      onErrorImage,
      onExport,
      openFilterModal,
    };
  },
  components: { EmptyBox, Paginator },
};
