import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63b724d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["tips-controller", $props.status])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("i", {
          class: _normalizeClass(["pi pi-bolt", $setup.getClassByStatus(true)])
        }, null, 2),
        _createElementVNode("span", {
          class: _normalizeClass(["mb-2 mx-2", $props.status == 'danger' && 'red'])
        }, _toDisplayString(_ctx.$t('tips')), 3)
      ]),
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tips, (tip) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass($setup.getClassByStatus(false)),
            key: tip
          }, [
            _createElementVNode("p", null, _toDisplayString(tip), 1)
          ], 2))
        }), 128))
      ])
    ], 2)
  ]))
}