
import { computed, onMounted, reactive, toRefs, watch } from 'vue'
import VueApexCharts from 'vue3-apexcharts'
import store, { SpotBalance } from '@/core/store';
import { AccountRepository } from '@/core/repository/account.repository';
import TransferComponent from '@/app/unit/components/TransferUsdToUsdtComp.vue';

export default {
    props:['assets','options' , 'sideList' , ],
    emits:['initTransfer'],
    components:{
    apexcharts: VueApexCharts,
    TransferComponent
},
    setup () {
        const state = reactive({
            totals: {
              spot:0,
              stake:0
            },
            showAsset:true,
            transferDialog:false,
            spotLoading:true,
            stakeLoading:false,
            spotData:[] as SpotBalance[],
            usdBalance: 0,
            geted:false

        })

          const GetSpotBalance = async () => {
            const token = localStorage.getItem('token'); 
            if (!(!!token)) return null;
            state.totals = {
              spot:0,
              stake:0
            }
            const data = store.state.wsAssets.data;
            console.log('data' , data);
            
            if (data) {
              AccountRepository.getInstance().GetSpotBalance({
                onReceive: (res: SpotBalance[]) => {
                  state.usdBalance = 0;
                  state.totals.spot = 0;
                  res.forEach(item => {

                    if (item.balance > 0 && item.symbol != 'usd') {
                      state.totals.spot += (Number(data[item.symbol.toUpperCase()].price_usd) * item.balance);
                    }

                    if (item.symbol == 'usd') {
                      state.usdBalance = item.balance
                    }
                  })
                  state.geted = true;
                  state.spotLoading = false
                },
                onLoadChange: (load) => state.spotLoading = load
              }) 
            }
            
          
        }

        watch(
        () => store.state.wsAssets.data,
        (first) => {
          if (!state.geted) {
            GetSpotBalance()
          } 
        }
      );

      
        const watchBalance = () => {
          store.subscribe((mutation, state) => {
              if(mutation.type == 'setMarketPrice' ||  mutation.type == 'updateBalance') {
                  GetSpotBalance();
              }
          });
        }

       onMounted(() => {
         
            GetSpotBalance();
          watchBalance();
       });
    
        return {
            ...toRefs(state),
            GetSpotBalance
        }
    }
}
