
import { profileStore } from "@/core/store/profile.store";
import {defineComponent, onMounted} from "vue";
import { useQueryProvider } from "vue-query";
export default defineComponent({
  setup() {
    useQueryProvider();
    onMounted(()=> {
      profileStore().getUserSpotBalance();
      profileStore().watchBalance();
      profileStore().getNotification();
    })
  }, 
});
