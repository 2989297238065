
import { onMounted, reactive, ref, toRefs } from "vue";
import store from "@/core/store";
import OverlayPanel from "primevue/overlaypanel";
import CoinListComponent from "@/app/unit/shared/coins/coinListComponent.vue";
import { mapState } from "vuex";
import { mapState as piniaMapState } from "pinia";
import { TradeRepository } from "@/core/repository/trade.repository";
import { ToastService } from "@/core/service/toast.service";
import { i18n } from "@/main";
import { MyStakeModel, StakeListModel } from "@/data/assets.model";
import { profileStore } from "@/core/store/profile.store";
import DialogSelectCoinTransfer from "./DialogSelectCoinTransfer.vue";
import { computed } from "@vue/reactivity";
import { AccountRepository } from "@/core/repository/account.repository";

export default {
  computed: {
    getBalance() {
      return (symbol: string) => {
        const { obj } = profileStore().spotBalance;
        return obj?.has(symbol.toLowerCase())
          ? obj.get(symbol.toLowerCase())
          : 0;
      };
    },
    ...piniaMapState(profileStore, ["spotBalance"]),
    ...mapState(["selectedSymbol", "assets"]),
  },
  components: {
    OverlayPanel,
    CoinListComponent,
    DialogSelectCoinTransfer,
  },

  setup(props, { emit }) {
    const state = reactive({
      isSpot: true,
      amount: null,
      from: "Spot",
      to: "USD",
      fromType: 1,
      loading: false,
      showCoinsx: false,
      spot: {
        list: [] as StakeListModel[],
        loading: false,
        selected: null as StakeListModel,
      },
      usd: {
        list: [],
        loading: false,
        selected: null,
      },
      stake: {
        list: [] as MyStakeModel[],
        loading: false,
        selected: null as MyStakeModel,
        balance: new Map<string, number>([]),
      },
    });

    const coinsPanel = ref();
    const fromPanel = ref();
    const toPanel = ref();
    const available = ref(0);

    const showCoins = (event) => coinsPanel.value.toggle(event);
    const showFromPnl = (event) => fromPanel.value.toggle(event);
    const showToPnl = (event) => toPanel.value.toggle(event);

    const store = profileStore();
    const getAvailable = computed(() => {
      // let available = 0;
      const { obj } = store.spotBalance;
      switch (state.fromType) {
        case 1:
          available.value = state.stake.balance.get("usdt") ?? 0;
          break;
        case 2:
          available.value = state.stake.balance.get("usd") ?? 0;
          break;
        case 3:
          const { symbol } = state.stake.selected;
          available.value = obj?.has(symbol.toLowerCase())
            ? obj.get(symbol.toLowerCase())
            : 0;
          break;
        default:
          break;
      }
      console.log("testttt : ", store);
      console.log("stateeee : ", state);

      return Number(available.value).toFixed(3);
    });

    const onSelectCoin = (data) => {
      state.amount = null;
      console.log("====================================");
      console.log("dataaaaa", data);
      console.log("====================================");
      switch (state.fromType) {
        case 1:
          state.spot.selected = data;
          break;
        case 2:
          state.usd.selected = data;
          break;
        case 3:
          state.stake.selected = data;
          break;
      }
      console.log("====================================");
      console.log("dataaa stateeeee", state);
      console.log("====================================");
      state.showCoinsx = false;
    };

    const getBalance = () => {
      const token = localStorage.getItem("token");
      if (!!!token) return null;

      // profileStore().getUserSpotBalance();

      TradeRepository.getInstance().GetMyStakes({
        onReceive: (res: MyStakeModel[]) => {
          console.log(res);

          state.stake.list = [...res];
          res.forEach((item) => {
            state.stake.balance.set(item.symbol, item.balance);
          });
          if (!state.stake.selected) {
            state.stake.selected =
              res[0] ??
              ({
                symbol: "Empty",
              } as MyStakeModel);
          }
        },
        onLoadChange: (load) => (state.stake.loading = load),
      });

      TradeRepository.getInstance().GetStakeAssets({
        onReceive: (res: StakeListModel[]) => {
          console.log(res);

          state.spot.list = [...res];

          if (!state.spot?.selected) {
            state.spot.selected =
              res[0] ??
              ({
                symbol: "Empty",
              } as StakeListModel);
          }
        },
        onLoadChange: (load) => (state.spot.loading = load),
      });

      AccountRepository.getInstance().GetSpotBalance({
        onReceive: (res) => {
          const usdt = res.find((item) => item.symbol == "usdt")?.balance ?? 0;
          const usd = res.find((item) => item.symbol == "usd")?.balance ?? 0;
          state.stake.balance.set("usdt", usdt);
          state.stake.balance.set("usd", usd);
        },
        onLoadChange: (load) => (state.spot.loading = load),
      });
    };

    const Transfer = () => {
      const { amount, fromType, from, to } = state;

      const stakeid =
        state[fromType == 1 ? "spot" : fromType == 2 ? "usd" : "stake"].selected
          ._id;

      if ((from == "Spot" && to == "USD") || (to == "Spot" && from == "USD")) {
        TradeRepository.getInstance()[
          to == "Spot" && from == "USD" ? "usdToUsdt" : "usdtToUsd"
        ]({
          body: { amount },
          onReceive: (res) => {
            state.amount = 0;
            ToastService.getInstance().success(
              `${i18n.global.tc("transfer")} ${i18n.global.tc(
                "transferSuccessful"
              )}`
            );
            getBalance();
            emit("onCallBack");
            profileStore().getUserSpotBalance();
          },
          onLoadChange: (load) => (state.loading = load),
        });
      } else {
        TradeRepository.getInstance()[
          fromType == 1 ? "AddStake" : "RemoveStake"
        ]({
          body:
            fromType == 1
              ? {
                  stakeid,
                  amount,
                }
              : {
                  stakewalletid: stakeid,
                  amount,
                },
          onReceive: (res) => {
            state.amount = 0;
            ToastService.getInstance().success(
              `${i18n.global.tc("transfer")} ${i18n.global.tc(
                "transferSuccessful"
              )}`
            );
            getBalance();
            emit("onCallBack");
            // profileStore().getUserSpotBalance();
          },
          onLoadChange: (load) => (state.loading = load),
        });
      }
    };

    onMounted(() => {
      getBalance();
    });

    const getFromList = (isFrom) => {
      if (isFrom) {
        switch (state.to) {
          case "Spot":
            return [
              { label: "USD", key: 2 },
              // { label: "Stake", key: 3 },
            ];
          case "USD":
            return [{ label: "Spot", key: 1 }];
          // case "Stake":
          //   return [{ label: "Spot", key: 1 }];
        }
      } else {
        switch (state.from) {
          case "Spot":
            return [
              { label: "USD", key: 2 },
              // { label: "Stake", key: 3 },
            ];
          case "USD":
            return [{ label: "Spot", key: 1 }];
          case "Stake":
            return [{ label: "Spot", key: 1 }];
        }
      }
    };

    const switchFrom = () => {
      let find;
      find = state[
        state.fromType == 1 ? "spot" : state.fromType == 2 ? "usd" : "stake"
      ].list.find(
        (item) =>
          item ==
          state[
            state.fromType == 1 ? "usd" : state.fromType == 2 ? "spot" : "stake"
          ].selected
      );
      if (!!find)
        state[
          state.fromType == 1 ? "spot" : state.fromType == 2 ? "usd" : "stake"
        ].selected = find;

      const def = {
        from: state.from,
        to: state.to,
      };
      state.from = def.to;
      state.to = def.from;

      if (state.from == "Spot") return (state.fromType = 1);
      else if (state.from == "usd") return (state.fromType = 2);
      else state.fromType = 3;

      console.log("====================================");
      console.log(state.fromType);
      console.log("====================================");
    };

    return {
      ...toRefs(state),
      showCoins,
      showFromPnl,
      showToPnl,
      onSelectCoin,
      getAvailable,
      Transfer,
      getFromList,
      coinsPanel,
      fromPanel,
      toPanel,
      switchFrom,
    };
  },
};
