
import { mapState } from 'vuex';
import { GRemoveSuffix } from '@/core/service/utils.service'

export default {
    props:['data'],
    emits:['onClose'],
    computed: {
     ...mapState({
        wsAssets:'wsAssets',
        theme:'theme',
     }),
    },
    setup () {
        
        const roundNumber = (val) => {
            return Number(val).toFixed(5)
        }

        return {
            roundNumber,
            GRemoveSuffix,
        }
    }
}
