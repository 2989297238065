
import { mapState } from "vuex";
import { mapState as PMapState } from "pinia";
import store from "@/core/store";
import { GRemoveSuffix } from "@/core/service/utils.service";
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
  watchEffect,
} from "vue";
import { profileStore } from "@/core/store/profile.store";
import { wsStore } from "@/core/store/webSoket.store";
import { LinearBackoff, WebsocketBuilder } from "websocket-ts/lib";
import { Constant } from "@/core/constant";

export default {
  props: ["full", "isMobile"],
  computed: {
    ...PMapState(profileStore, ["isBuy"]),
    ...mapState(["selectedSymbol", "wsAssets", "averagePrice"]),
  },
  setup() {
    let _interval = null;
    const state = reactive({
      orderBooks: [] as any,
      averagePrice: 0,
    });
    const webSocket = ref();

    const getOrderBooksComputed = computed(() => {
      let askTotal = 0;
      let askAverage = 0;
      let len = 0;

      state.orderBooks.data.ask = state.orderBooks.data.ask.map((item) => {
        askTotal += Number(item[1]);
        askAverage += Number(item[0]);
        item[2] = String(((Number(item[1]) * 100) / askTotal).toFixed(3));
        item[3] = String(askTotal.toFixed(2));
        return item;
      });

      state.orderBooks.data.ask = state.orderBooks.data.ask
        .slice(0, 13)
        .sort((a, b) => Number(a[0]) - Number(b[0]));
      // len += state.orderBooks.data.ask.length;

      let bidTotal = 0;
      let bidAverage = 0;

      state.orderBooks.data.bid = state.orderBooks.data.bid.map((item) => {
        bidTotal += Number(item[1]);
        bidAverage += Number(item[0]);
        item[2] = String(((Number(item[1]) * 100) / bidTotal).toFixed(3));
        item[3] = String(bidTotal.toFixed(2));
        return item;
      });

      state.orderBooks.data.bid = state.orderBooks.data.bid
        .slice(0, 13)
        .sort((a, b) => Number(b[0]) - Number(a[0]));

      len = state.orderBooks.data.bid.length + state.orderBooks.data.ask.length;

      state.averagePrice = (askAverage + bidAverage) / len / 4;
      store.state.orderBooks = state.orderBooks;
      return state.orderBooks.data;
    });

    const getSelectedSymbol = computed(() =>
      store.state.selectedSymbol.spot?.asset
        ? store.state.selectedSymbol.spot.asset
        : "btc"
    );

    const getOrderBook = () => {
      webSocket.value = new WebsocketBuilder(Constant.Ws_Base_Url)
        .onClose((i, ev) => {
          console.log("closed");
        })
        .onError((i, ev) => {
          console.log("error");
        })
        .onMessage((i, ev) => {
          const data = JSON.parse(ev.data);

          state.orderBooks = data;
        })
        .withBackoff(new LinearBackoff(0, 1000, 5000))
        .onRetry((i, ev) => {
          console.log("retry");
        })
        .build();
    };

    if (!!_interval) clearInterval(_interval);
    _interval = setInterval(() => {
      if (webSocket.value) sendDataSocket(getSelectedSymbol.value);
    }, 500);

    const sendDataSocket = (symbol: any) => {
      webSocket.value.send(
        JSON.stringify({
          event: "spot",
          symbol: symbol,
        })
      );
    };

    const closeWebSocket = () => {
      webSocket.value.close();
      webSocket.value = null;
    };

    const selectOrderBookPrice = (data: any) => {
      store.state.selectedOrderBookPrice = data;
    };

    watch(getSelectedSymbol, () => {
      closeWebSocket();
      getOrderBook();
      store.state.orderBooks = null;
      state.orderBooks = [];
    });

    onMounted(() => {
      getOrderBook();
    });
    onBeforeUnmount(() => {
      closeWebSocket();
    });

    return {
      ...toRefs(state),
      GRemoveSuffix,
      getOrderBooksComputed,
      selectOrderBookPrice,
    };
  },
};
