
import store from '@/core/store';
import { reactive, toRefs } from 'vue'
import AutoComplete from 'primevue/autocomplete';
import { Constant } from '@/core/constant';
import { useRouter } from 'vue-router';

export default {
    components:{
        AutoComplete
    },
    setup () {
        const state = reactive({
            searchInput:'',
            filteredCountries:[],
        });
        const onErrorImage = (e) => {
            e.target.src = Constant.ErrorImageUrl
        }

        const searchCountry = (event) => {
            const {marketList} = store.state.wsAssets;
            setTimeout(() => {
                if (!event.query.trim().length) {
                    state.filteredCountries = [...marketList];
                }
                else {
                    state.filteredCountries = marketList.filter((country) => {
                        return country.asset.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        };
        const onChangeInput = (str) => {
            setTimeout(() => {
                state.searchInput = str
            }, 100);
        }

        const onRefresh = () => {
            setTimeout(() => {
            window.location.reload();
        }, 1000);
        }
        return {
            ...toRefs(state),
            searchCountry,
            onErrorImage,
            onChangeInput,
            onRefresh
        }
    }
}
