import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, isMemoSame as _isMemoSame, withMemo as _withMemo, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-189c59dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table mb-0" }
const _hoisted_2 = { class: "d-none d-lg-block" }
const _hoisted_3 = {
  key: 0,
  id: "askOrderBook",
  ref: "askOrderBook"
}
const _hoisted_4 = { class: "red" }
const _hoisted_5 = { class: "d-none d-lg-block" }
const _hoisted_6 = {
  key: 1,
  class: "ob-heading"
}
const _hoisted_7 = { class: "d-flex flex-row" }
const _hoisted_8 = { class: "flex-1 flex-column" }
const _hoisted_9 = { class: "flex-1 flex-column" }
const _hoisted_10 = { class: "red d-none d-md-block flex-1 flex-column" }
const _hoisted_11 = { class: "green" }
const _hoisted_12 = { class: "d-none d-lg-block" }
const _hoisted_13 = {
  key: 3,
  class: "ob-heading"
}
const _hoisted_14 = { class: "d-flex flex-row" }
const _hoisted_15 = { class: "flex-1 flex-column" }
const _hoisted_16 = { class: "flex-1 flex-column" }
const _hoisted_17 = { class: "red d-none d-md-block flex-1 flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["order-book mb-0", $props.full && 'h-100'])
  }, [
    _createElementVNode("table", _hoisted_1, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", null, _toDisplayString(_ctx.$t("volume")) + "(" + _toDisplayString(_ctx.selectedSymbol && _ctx.selectedSymbol.spot
                ? $setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)
                : "BTC") + ") ", 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t("price")) + "(" + _toDisplayString(_ctx.selectedSymbol && _ctx.selectedSymbol.spot
                ? $setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)
                : "BTC") + ") ", 1),
          _createElementVNode("th", _hoisted_2, _toDisplayString(_ctx.$t("total")) + "(" + _toDisplayString(_ctx.selectedSymbol && _ctx.selectedSymbol.spot
                ? $setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)
                : "BTC") + ") ", 1)
        ])
      ]),
      (!$props.isMobile || _ctx.isBuy)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_3, [
            (_ctx.orderBooks.length !== 0)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.getOrderBooksComputed.ask, (ask, index, ___, _cached) => {
                  const _memo = ([_ctx.orderBooks])
                  if (_cached && _cached.key === index && _isMemoSame(_cached, _memo)) return _cached
                  const _item = (_openBlock(), _createElementBlock("tr", {
                    onClick: ($event: any) => (
              $setup.selectOrderBookPrice(
                Number(ask[0]).toFixed(8).replace(/\.0+$/, '')
              )
            ),
                    key: index,
                    class: _normalizeClass(`red-bg cp`)
                  }, [
                    _createElementVNode("div", {
                      class: "line",
                      style: _normalizeStyle({ width: Math.round(Number(ask[2])) + '%' })
                    }, null, 4),
                    _createElementVNode("td", _hoisted_4, _toDisplayString(Number(ask[1]).toFixed(3)), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.$filter.currency(Number(ask[0]).toFixed(8).replace(/\.0+$/, ""))), 1),
                    _createElementVNode("td", _hoisted_5, _toDisplayString(ask[3]), 1)
                  ], 8, ["onClick"]))
                  _item.memo = _memo
                  return _item
                }, _cache, 0), 128))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(5, (item) => {
                  return _createElementVNode("tr", { key: item }, [
                    _createVNode(_component_Skeleton, {
                      width: "100%",
                      height: "3rem",
                      class: "mb-1",
                      borderRadius: "8px"
                    })
                  ])
                }), 64))
          ], 512))
        : _createCommentVNode("", true),
      (!$props.isMobile)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_6, [
            _createElementVNode("tr", _hoisted_7, [
              _createElementVNode("td", _hoisted_8, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("lastPrice")), 1),
                (_ctx.wsAssets.data)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.$filter.currency(
                  Number(
                    _ctx.wsAssets.data[$setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)]
                      .price_usd
                  ).toFixed(6)
                )), 1)
                    ], 64))
                  : (_openBlock(), _createBlock(_component_Skeleton, {
                      key: 1,
                      width: "100%",
                      height: "1rem",
                      borderRadius: "3px"
                    }))
              ]),
              _createElementVNode("td", _hoisted_9, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("averagePrice")), 1),
                (Number(_ctx.averagePrice) > 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.$filter.currency(Number(_ctx.averagePrice).toFixed(2))), 1)
                    ], 64))
                  : (_openBlock(), _createBlock(_component_Skeleton, {
                      key: 1,
                      width: "100%",
                      height: "1rem",
                      borderRadius: "3px"
                    }))
              ]),
              _createElementVNode("td", _hoisted_10, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("change")), 1),
                (_ctx.wsAssets && _ctx.wsAssets.data)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass(
                  _ctx.wsAssets.data[
                    _ctx.selectedSymbol &&
                    _ctx.selectedSymbol.spot &&
                    $setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)
                      ? $setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)
                      : 'BTC'
                  ].change_rate > 0
                    ? 'green'
                    : 'red'
                )
                    }, [
                      _createTextVNode("% " + _toDisplayString(Number(
                    _ctx.wsAssets.data[
                      _ctx.selectedSymbol &&
                      _ctx.selectedSymbol.spot &&
                      $setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)
                        ? $setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)
                        : "BTC"
                    ].change_rate * 100
                  ).toFixed(2)) + "% ", 1),
                      _createElementVNode("i", {
                        class: _normalizeClass(["pi", 
                    _ctx.wsAssets.data[
                      _ctx.selectedSymbol &&
                      _ctx.selectedSymbol.spot &&
                      $setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)
                        ? $setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)
                        : 'BTC'
                    ].change_rate > 0
                      ? 'pi-angle-up green'
                      : 'pi-angle-down red'
                  ])
                      }, null, 2)
                    ], 2))
                  : (_openBlock(), _createBlock(_component_Skeleton, {
                      key: 1,
                      width: "100%",
                      height: "1rem",
                      borderRadius: "3px"
                    }))
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (!$props.isMobile || !_ctx.isBuy)
        ? (_openBlock(), _createElementBlock("tbody", {
            key: 2,
            class: _normalizeClass($props.full && 'h-100'),
            style: _normalizeStyle({ 'max-height': $props.full ? '285px' : 'auto' })
          }, [
            (_ctx.orderBooks.length !== 0)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.getOrderBooksComputed.bid, (bid, index, ___, _cached) => {
                  const _memo = ([_ctx.orderBooks.data.bid])
                  if (_cached && _cached.key === index && _isMemoSame(_cached, _memo)) return _cached
                  const _item = (_openBlock(), _createElementBlock("tr", {
                    onClick: ($event: any) => (
              $setup.selectOrderBookPrice(
                Number(bid[0]).toFixed(8).replace(/\.0+$/, '')
              )
            ),
                    key: index,
                    class: _normalizeClass(`green-bg  cp`)
                  }, [
                    _createElementVNode("div", {
                      class: "line",
                      style: _normalizeStyle({ width: Math.round(Number(bid[2])) + '%' })
                    }, null, 4),
                    _createElementVNode("td", _hoisted_11, _toDisplayString(Number(bid[1]).toFixed(3)), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.$filter.currency(Number(bid[0]).toFixed(8).replace(/\.0+$/, ""))), 1),
                    _createElementVNode("td", _hoisted_12, _toDisplayString(bid[3]), 1)
                  ], 8, ["onClick"]))
                  _item.memo = _memo
                  return _item
                }, _cache, 2), 128))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(6, (item) => {
                  return _createElementVNode("tr", { key: item }, [
                    _createVNode(_component_Skeleton, {
                      width: "100%",
                      height: "3rem",
                      class: "mb-1",
                      borderRadius: "8px"
                    })
                  ])
                }), 64))
          ], 6))
        : _createCommentVNode("", true),
      ($props.isMobile)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_13, [
            _createElementVNode("tr", _hoisted_14, [
              _createElementVNode("td", _hoisted_15, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("lastPrice")), 1),
                (_ctx.wsAssets.data)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.$filter.currency(
                  Number(
                    _ctx.wsAssets.data[$setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)]
                      .price_usd
                  ).toFixed(6)
                )), 1)
                    ], 64))
                  : (_openBlock(), _createBlock(_component_Skeleton, {
                      key: 1,
                      width: "100%",
                      height: "1rem",
                      borderRadius: "3px"
                    }))
              ]),
              _createElementVNode("td", _hoisted_16, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("averagePrice")), 1),
                (Number(_ctx.averagePrice) > 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.$filter.currency(Number(_ctx.averagePrice).toFixed(2))), 1)
                    ], 64))
                  : (_openBlock(), _createBlock(_component_Skeleton, {
                      key: 1,
                      width: "100%",
                      height: "1rem",
                      borderRadius: "3px"
                    }))
              ]),
              _createElementVNode("td", _hoisted_17, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("change")), 1),
                (_ctx.wsAssets && _ctx.wsAssets.data)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass(
                  _ctx.wsAssets.data[
                    _ctx.selectedSymbol &&
                    _ctx.selectedSymbol.spot &&
                    $setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)
                      ? $setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)
                      : 'BTC'
                  ].change_rate > 0
                    ? 'green'
                    : 'red'
                )
                    }, [
                      _createTextVNode("% " + _toDisplayString(Number(
                    _ctx.wsAssets.data[
                      _ctx.selectedSymbol &&
                      _ctx.selectedSymbol.spot &&
                      $setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)
                        ? $setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)
                        : "BTC"
                    ].change_rate * 100
                  ).toFixed(2)) + "% ", 1),
                      _createElementVNode("i", {
                        class: _normalizeClass(["pi", 
                    _ctx.wsAssets.data[
                      _ctx.selectedSymbol &&
                      _ctx.selectedSymbol.spot &&
                      $setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)
                        ? $setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)
                        : 'BTC'
                    ].change_rate > 0
                      ? 'pi-angle-up green'
                      : 'pi-angle-down red'
                  ])
                      }, null, 2)
                    ], 2))
                  : (_openBlock(), _createBlock(_component_Skeleton, {
                      key: 1,
                      width: "100%",
                      height: "1rem",
                      borderRadius: "3px"
                    }))
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}