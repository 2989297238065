
import { Constant } from '@/core/constant';
import Carousel from 'primevue/carousel';
import {  reactive, toRefs } from 'vue';
import MarketsList from "../../components/MarketsList.vue";
import store from '@/core/store';
import { mapState } from 'vuex';

export default {
  components: {
    MarketsList,
    Carousel
  },
  // computed:{
  //   ...mapState({
  //     Futures: (state:any) => state.FuturesModule.tradeVolume
  //   })
  // },
  setup() {
    const state = reactive({
      responsiveOptions: [{
          breakpoint: '1024px',
          numVisible: 3,
          numScroll: 3
        },
        {
          breakpoint: '600px',
          numVisible: 2,
          numScroll: 2
        },
        {
          breakpoint: '480px',
          numVisible: 1,
          numScroll: 1
        }
      ]
    });

    const sortList = () => {
      return store.state.wsAssets.marketList.slice(0,8).sort((a, b) => a.circulation_usd_rank - b.circulation_usd_rank)
    }

   const onErrorImage = (e) => {
       e.target.src = Constant.ErrorImageUrl
    }

    return {
      ...toRefs(state),
      onErrorImage,
      sortList
    }
  }
};
