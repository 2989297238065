
import { mapState , useStore } from 'vuex';
import { profileStore } from '@/core/store/profile.store';
import { useConfirm } from 'primevue/useconfirm';
import { i18n } from '@/main';
import { AccountRepository } from '@/core/repository/account.repository';
import { reactive, ref, toRefs } from 'vue-demi';

export default {
    computed:{
        ...mapState({
            userDetails:'userDetails',
        })
    },
    setup () {
        const confirm = useConfirm();
        const state = reactive({
            loading: false,
            code:''
        })
        function confirmDelete(event ) {
            confirm.require({
                target: event.currentTarget,
                message: 'Are you sure to UnBind ToTp ?' ,
                icon: 'pi pi-exclamation-triangle',
                acceptLabel:i18n.global.tc('approve'),
                rejectLabel:i18n.global.tc('cancel'),
                accept: () => {
                    AccountRepository.getInstance().UnBindToTp({
                        onReceive:(res)=> {
                        },onLoadChange:(load) => state.loading = load
                    })
                },
            });
        };
        const store = profileStore();

         const initDialog = (key) => {
            store.initDialog( key );
        }
    
        return {
            initDialog,
            confirmDelete,
            ...toRefs(state)
        }
    }
}
