import {Constant} from '../constant/index';
import {Repository, ReturnResponse} from './repository';

export class AuthRepository extends Repository {
  private static instance: AuthRepository
  public baseUrl = Constant.BASE_URL


  public static getInstance(): AuthRepository {
    if (!AuthRepository.instance) AuthRepository.instance = new AuthRepository()
    return AuthRepository.instance
  }

  public checkEmail(data: ReturnResponse) {
    data.url = `${this.baseUrl}register/checkemail`;
    this.post(data)
  } 
  
  public checkUsername(data: ReturnResponse) {
    data.url = `${this.baseUrl}register/checkusername`;
    this.post(data)
  }

  public Register(data: ReturnResponse) {
    data.url = `${this.baseUrl}register`;
    this.post(data)
  } 
  
  public confirmAgain(data: ReturnResponse) {
    data.url = `${this.baseUrl}register/confirmagain`;
    this.post(data)
  } 

  public refresh(data: ReturnResponse) {
    data.url = `${this.baseUrl}refresh`;
    this.post(data)
  } 

  public Login(data: ReturnResponse) {
    data.url = `${this.baseUrl}login`;
    this.post(data)
  } 
  
  public loginToken(data: ReturnResponse) {
    data.url = `${this.baseUrl}login/logintoken`;
    this.post(data)
  } 
  
  public ForgetPassword(data: ReturnResponse) {
    data.url = `${this.baseUrl}forgetpassword`;
    this.post(data)
  } 
  
  public GetAccountInfo(data: ReturnResponse) {
    data.url = `${this.baseUrl}user/info`;
    this.get(data)
  } 

}
