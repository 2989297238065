<template>
    <div>
        <div class="row">
            <div class="col-12 mt-4 px-5">
                <trading-chart :showTools="true" :isFutures="false" :height="'800px'" />
            </div>
        </div>
    </div>
</template>

<script>
import TradingChart from '../../components/TradingChart.vue'
    export default {
  components: { TradingChart },
        
    }
</script>

<style lang="scss" scoped>

</style>