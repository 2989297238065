import { defineStore } from "pinia";
import { LinearBackoff, Websocket, WebsocketBuilder } from "websocket-ts/lib";
import { Constant } from "@/core/constant/index";

export const wsStore = defineStore("wsStore", {
  state: (): State => {
    return {
      webSocketController: null,
      wsData: null,
      loading: false,
      selected: "btc",
      wsDataOrderBook: null,
    };
  },
  actions: {
    connect(data: { event: string; symbol?: string }) {
      console.log("====================================");
      console.log("eventttttt , ", data.event);
      console.log("====================================");
      let _counter = 0;
      const store = wsStore();
      if (store.webSocketController || store.loading) return true;
      store.loading = true;
      store.webSocketController = new WebsocketBuilder(Constant.Ws_Base_Url)
        .onClose((i, ev) => {
          console.log("closed");
        })
        .onError((i, ev) => {
          console.log("error");
        })
        .onMessage((i, ev) => {
          _counter += 1;
          if (ev && ev.data) {
            if (checkJson(String(ev.data))) {
              const data = JSON.parse(ev.data);
              // console.log("====================================");
              // console.log(data);
              // console.log("====================================");
              if (data.event == "market") {
                store.wsData = { ...data };
              }
              if (data.event == "spot") {
                if (store.selected.toLowerCase() == data.symbol.toLowerCase()) {
                  store.wsDataOrderBook = { ...data };
                }
              }
              // if (!(!data || Object.keys(data).length === 0)) {
              //   if (data.event == "market") {
              //     store.wsData = { ...data };
              //   } else if (data.event == "spot") {
              //     console.log("====================================");
              //     console.log(data);
              //     console.log("====================================");
              //     if (
              //       store.selected.toLowerCase() == data.symbol.toLowerCase()
              //     ) {
              //       store.wsDataOrderBook = { ...data };
              //     }
              //   }
              // }
            }
          }
        })
        .withBackoff(new LinearBackoff(0, 1000, 5000))
        .onRetry((i, ev) => {
          console.log("retry");
        })
        .build();
    },
    sendData(data: { event: string; symbol?: string }) {
      const store = wsStore();
      if (data.symbol) {
        // console.log('sended' , data.symbol,store.selected);
        // if(store.selected!=data.symbol){
        //    return store.webSocketController.close();
        // }
        store.selected = data.symbol;
      }

      store.webSocketController.send(JSON.stringify(data));
    },
  },
});

interface State {
  webSocketController: Websocket;
  wsData: any;
  wsDataOrderBook: any;
  loading: boolean;
  selected: string;
}

const checkJson = (text): boolean => {
  if (
    /^[\],:{}\s]*$/.test(
      text
        .replace(/\\["\\\/bfnrtu]/g, "@")
        .replace(
          /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
          "]"
        )
        .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
    )
  ) {
    return true;
  } else {
    return false;
  }
};
