
import MarketPairs from "../../components/MarketPairs.vue";
import TradingChart from "../../components/TradingChart.vue";
import MarketTrade from "../../components/MarketTrade.vue";
import OrderBook from "../../components/OrderBook.vue";
import MarketHistory from "../../components/MarketHistory.vue";
import HistoryOrder from "../../components/HistoryOrder.vue";
import Sidebar from "primevue/sidebar";
import { onBeforeUnmount, onMounted, reactive, ref, toRefs } from "vue";
import store from "@/core/store";
import { mapState } from "vuex";
import { GRemoveSuffix } from "@/core/service/utils.service";
import HeadPanelTradeComponent from "../../components/HeadPanelTradeComponent.vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { useQuery } from "vue-query";
import { profileStore } from "@/core/store/profile.store";
import { EnumKeys } from "@/core/enum";
export default {
  computed: {
    ...mapState({
      tvHeight: (state: any) => state.TradeModule.tvHeight,
      selectedSymbol: (state: any) => state.selectedSymbol,
      wsAssets: (state: any) => state.wsAssets,
    }),
    screenWidth() {
      return window.screen.width;
    },
  },
  components: {
    MarketPairs,
    TradingChart,
    MarketTrade,
    OrderBook,
    MarketHistory,
    HistoryOrder,
    Sidebar,
    HeadPanelTradeComponent,
    Accordion,
    AccordionTab,
  },
  setup() {
    const state = reactive({
      sidebar: false,
      showChart: false,
    });

    const getOrderBook = () => {
      // store.dispatch("desConnectOrderBook");
      // store.dispatch("desConnectFuturesOrderBook");
      // store.dispatch("getOrderBooks");
    };
    const token = ref(null);
    onMounted(() => {
      getOrderBook();
      // profileStore().getUserSpotBalance();
      token.value = localStorage.getItem(EnumKeys.KeyToken);
    });
    const getUserBalance = () => {
      return new Promise((resolve) => {
        profileStore().getUserSpotBalance();
        resolve(true);
      });
    };

    useQuery(["userBalance"], getUserBalance, {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      refetchOnMount: false,
      retry: 3,
      refetchInterval: 10000,
    });

    onBeforeUnmount(() => {
      store.dispatch("desConnectOrderBook");
    });

    return {
      ...toRefs(state),
      GRemoveSuffix,
    };
  },
};
