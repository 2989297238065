
import { CoinInfoModel } from '@/data/coinInfo.model';
import { onMounted, onUpdated, reactive, ref, toRefs, watch, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { AccountRepository } from '@/core/repository/account.repository';
import { Constant } from '@/core/constant';
import store from '@/core/store';
import Divider from 'primevue/divider';
import Menu from 'primevue/menu';
import VueTradingView from "vue-trading-view/src/vue-trading-view.vue";
import AutoCompleteSearchCoin from '../../components/AutoCompleteSearchCoin.vue';

export default {
    components:{
        Divider,
        Menu,
        VueTradingView,
        AutoCompleteSearchCoin
    },
   
    computed:{
        getDetails(){
            return key => {
                if (!store.state.wsAssets?.data) return 0
                const id = useRoute().params.id as any;
                return store.state.wsAssets?.data[id][key];
            }
        }
    },
    setup () {
        const state = reactive({
            info: null as CoinInfoModel,
            loading:false,
            items:[{
                items: []},
            ]
        });
        const router = useRouter();
        const menu = ref();
        const getInfo = () => {
            try {
                const {id} = useRoute().params;
                AccountRepository.getInstance().GetCoinInfo({
                    urlContent:{id},
                    onReceive:(res:CoinInfoModel)=> {
                        console.log(res);
                         state.info = res;
                         state.items[0].items = [];
                        state.info.social_url.forEach((data)=> {
                            state.items[0].items.push(
                                    {
                                    label:  data.media,
                                    icon: `pi pi-${data.media == 'Medium' ? 'sitemap' : data.media.toLowerCase()}`,
                                    command: () => {
                                        outSide(data.url)
                                    }
                                }
                                )
                        })
                    },onLoadChange:(load)=> state.loading = load
                });
            } catch (error) {
                
            }
        };

        const onErrorImage = (e) => {
            e.target.src = Constant.ErrorImageUrl
        }

        onMounted(()=> {
            getInfo();
        })

     
       
        const outSide = (url:string) =>{
            Object.assign(document.createElement('a'), {
                target: '_blank',
                href: url,
            }).click();
        }

        const showMore = () => {
            window.scrollTo({
                top:1000
            });
        }
       
        const getOptions = () => {
            try {
                const {id} = useRoute().params;
                
                const defaultOption = {
                    symbol: id+'USDT',
                    theme: store.state.theme ? 'light' : 'dark',
                    width:  '60vw',
                    height: '430px',
                } 
                return {...defaultOption}
            } catch (error) {}
        }

        return {
            ...toRefs(state),
            onErrorImage,
            outSide,
            menu,
            getOptions,
            showMore
        }
    }
}
