import { Constant } from "../constant";
import { WebSocketService } from "../service/webSocket";

export const FuturesModule = {
    state: () => ({
        wsTrade:null,
        itsClosed:false,
        tvHeight:'27.4rem',
    }),
    
     mutations: {
        connectWsFutures(state , payload) {
            state.itsClosed = false;
            state.wsTrade = new WebSocketService(`${Constant.Ws_Base_Url}/future?market=${payload ? payload :'BTCUSDT'}`)
        },
        onSetOrder(state) {},
        updateBalance(){},
        calcFutureAsset() {}
    },
     actions:{
        async getFuturesOrderBooks({ state , commit , rootState}) {
            try {
                this.wsTrade.closeConnection();
                this.wsTrade = null;
            } catch (error) { }
            
            // commit('connectWsFutures' , rootState.selectedFuturesDetails.symbol ? rootState.selectedFuturesDetails.symbol.name : null );

            state.wsTrade.getMainList((wsData:OrderBookInterFace)=> {
                if (!state.itsClosed) {
                    if (wsData) {
                        let askTotal = 0;
                        let bidTotal = 0;
                        
                        let askAverage = 0;
                        let bidAverage = 0;
        
                        let orderBook:OrderBookInterFace = null;
                        orderBook = wsData;
                        let len = 0;
        
                        //start bid
        
                        orderBook.bid = orderBook.bid.map(item => {
                            bidTotal += Number(item[1]);
                            bidAverage += Number(item[0]);
                            item[2] = String(((Number(item[1]) * 100) / bidTotal).toFixed(3));
                            item[3] = String(bidTotal.toFixed(2));
                            return item;
                        });
                        orderBook.bid = orderBook.bid.slice(-13).sort((a, b) => Number(a[2]) - Number(b[2]));
                    len += orderBook.bid.length;
                    //end bid
        
                        //start ask
                        orderBook.ask = orderBook.ask.map(item => {
                            askTotal += Number(item[1]);
                            askAverage += Number(item[0]);
                            item[2] = String(((Number(item[1]) * 100) / askTotal).toFixed(3));
                            item[3] = String(askTotal.toFixed(2));
                            return item;
                        });
        
                        orderBook.ask = orderBook.ask.slice(0,13).sort((a, b) => Number(b[2]) - Number(a[2]))
                        len += orderBook.ask.length;
        
                        const averagePrice = (askAverage + bidAverage) / (len)
                        
                        //end ask
                        commit('setOrderBook', {
                            orderBook,
                            averagePrice,
                            isFutures:true
                        }, { root: true })
                    }
                } else {
                    if (state.wsTrade) {
                        state.wsTrade.closeConnection();
                        if (state.wsTrade) state.wsTrade = null
                    }
                }
            })
        },
        desConnectFuturesOrderBook({state}) {
            console.log('closed Futures');
            if (state.wsTrade) {
                state.itsClosed = true;
                state.wsTrade.closeConnection();
            }
        },
     }
}

interface OrderBookInterFace {
    ask: string[][];
    bid: string[][];
    last: string;
    time: number;
    checksum: number;
}