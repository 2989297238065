
import { AccountRepository } from '@/core/repository/account.repository';
import { onMounted, reactive, toRefs } from 'vue-demi';
export default {
    setup () {
        const state = reactive({
            notifications: [] as NotificationsModel[],
            page:1,
            total:0,
            loading:false,
            selected:null,
            showDialog:false,
            massage:{
                data:null,
                loading:false
            }
        });

        const getNotifications = (page:number) => {
            AccountRepository.getInstance().GetPopups({
            query:`page=${page}&limit=10`, 
                onReceive:(res)=> {
                    state.notifications = res.messages;
                },onLoadChange:(load)=> state.loading = load
            })
        }

        const onPage = (event) => {
            if (event.page == state.page)
                return;
                getNotifications(event.page + 1);
        };
        
        onMounted(() => {
            getNotifications(1);
        })

        const onRowSelect = (event) => {
            const data = event?.data as NotificationsModel;
            state.showDialog = true;
            AccountRepository.getInstance().GetNotificationMassage({
                query:data._id,
                onReceive:(res)=> {
                    const index = state.notifications.findIndex((item)=> item._id == data._id);
                    state.notifications[index].seen = true;
                    console.log(res);
                    state.massage.data = res.msg
                },onLoadChange:(load)=> state.massage.loading = load
            })
        };

        
        return {
            ...toRefs(state),
            onPage,
            onRowSelect
        }


    }
}
export interface NotificationsModel {
	_id: string;
	admin: string;
	sent: string;
	type: string;
	msg: string;
	title: string;
	date: string;
	__v: number;
  seen:boolean;
}

