
import emptyBox from '@/app/unit/shared/emptyBox/emptyBox.vue'
import { FilterMatchMode } from 'primevue/api';
import {  onMounted, reactive, ref, toRefs, watch } from 'vue'
import Menu from 'primevue/menu';
import { i18n } from '@/main';
import { Constant } from '@/core/constant';
export default {
  components:{
    emptyBox,
    Menu
  },
  setup () {
    const state = reactive({
      tabs: [],
      selected:null,
       filter: {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
       },
       sortBy:'circulation_usd_rank',
       sortOrder:-1,
       items:[],
       marketList:[]
    });

    const onErrorImage = (e) => {
       e.target.src = Constant.ErrorImageUrl
    }

    const menu = ref();
    const initMenu = (key) => {
      
      state.sortBy = key;
      state.sortOrder = state.sortOrder == 1 ? -1 : 1;

      const getIcon = (originalKey) :string => {
        return state.sortBy == originalKey ? state.sortOrder == 1 ? 'pi pi-sort-amount-up':'pi pi-sort-amount-down'  : 'pi pi-sort'
      };

      state.items = [
        {
          label: i18n.global.tc('coin'),
          icon: getIcon('symbol'),
          command: () => initMenu('symbol'),
        },
        {
          label: i18n.global.tc('price'),
          icon: getIcon('price_usd'),
          command: () => initMenu('price_usd')
        },
        {
          label: `${i18n.global.tc('change')} (24)`,
          icon: getIcon('change_rate'),
          command: () => initMenu('change_rate')
        },
        {
          label: `${i18n.global.tc('circulation')} (USD)`,
          icon: getIcon('circulation_usd_rank'),
          command: () => initMenu('circulation_usd_rank')
        },
        {
          label: `${i18n.global.tc('volume')} (USD)`,
          icon: getIcon('volume_usd'),
          command: () => initMenu('volume_usd')
        }
      ]
    }

    onMounted(() => {
      initMenu('circulation_usd_rank')
     
    })

    const toggleFilter = (event) => {
      menu.value.toggle(event);
    };


    return {
      ...toRefs(state),
      initMenu,
      toggleFilter,
      menu,
      onErrorImage
    }
  }
}
