import { EnumKeys } from "@/core/enum";
import { AuthRepository } from "@/core/repository/auth.repository";
import router from "@/core/router/router";
import { LocalStorageService } from "@/core/service/localStorage.service";
import { ToastService } from "@/core/service/toast.service";
import { AccountRepository } from "@/core/repository/account.repository";
import { i18n } from "@/main";
import { profileStore } from "@/core/store/profile.store";
import store from "@/core/store";

export class loginState {
  postData = {
    username: "",
    email: "",
    password: "",
  };

  totp = {
    dialog: false,
    value: "",
    loading: false,
  };

  loading = false;
  allowCaptcha = false;
  dialogSuccessfulRegister = true;
  rememberMe = false;
  onLogin(token: string) {
    console.log("looooooggggiiiin");

    if (!token) router.push("/noAccess");
    const body = {
      token,
    };

    AuthRepository.getInstance().loginToken({
      body,
      onReceive: (res) => {
        LocalStorageService.getInstance().setItem(
          EnumKeys.KeyRefreshToken,
          res.refresh
        );
        LocalStorageService.getInstance().setItem(EnumKeys.KeyToken, res.token);
        if (!res.totp) {
          store.commit("getAllAssets");
          store.commit("getUserDetails");
          profileStore().hasToTp = false;
          profileStore().getNotification();
          router.push("/spotTrade");
        } else {
          profileStore().hasToTp = true;
          this.totp.dialog = true;
        }
      },
      onLoadChange: (load) => (this.loading = load),
      onError: (err) => {
        console.log("err ", err);
      },
    });
  }

  loginTotp() {
    AccountRepository.getInstance().LoginTotp({
      body: {
        code: this.totp.value,
      },
      onLoadChange: (load) => (this.totp.loading = load),
      onReceive: (res) => {
        location.replace("https://cex.qfmarkets.com/#/spotTrade")
        location.reload()
        // router.push("/");
      },
    });
  }

  unAvailableToTp() {
    ToastService.getInstance().info("coaming soon.");
  }
}
