import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b8869452"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel-controller" }
const _hoisted_2 = { class: "child" }
const _hoisted_3 = { class: "f-20 mb-0" }
const _hoisted_4 = { class: "child" }
const _hoisted_5 = { class: "f-13 gary" }
const _hoisted_6 = { class: "child" }
const _hoisted_7 = { class: "gary" }
const _hoisted_8 = { class: "child" }
const _hoisted_9 = { class: "gary" }
const _hoisted_10 = { class: "f-13" }
const _hoisted_11 = { class: "child" }
const _hoisted_12 = { class: "gary" }
const _hoisted_13 = { class: "f-13" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.wsAssets && _ctx.wsAssets.data &&  (_ctx.selectedSymbol && _ctx.selectedSymbol.spot))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString((_ctx.selectedSymbol.spot.asset)), 1)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", {
                class: _normalizeClass(["f-16", Number(_ctx.wsAssets.data[$setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)].price_usd) > 0 ? 'green' : 'red'])
              }, _toDisplayString(_ctx.$filter.currency(Number(_ctx.wsAssets.data[$setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)].price_usd).toFixed(6))), 3),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$filter.currency(_ctx.wsAssets.data[$setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)].price_usd)) + " " + _toDisplayString($setup.USDTORUSDC(_ctx.selectedSymbol.spot.asset)), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, "24 H " + _toDisplayString(_ctx.$t('change')), 1),
              _createElementVNode("span", {
                class: _normalizeClass(["f-13", Number(_ctx.wsAssets.data[$setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)].change_rate) > 0 ? 'green' : 'red'])
              }, _toDisplayString(Number(_ctx.wsAssets.data[$setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)].change_rate).toFixed(2)) + " % ", 3)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('volume')), 1),
              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$filter.round(_ctx.wsAssets.data[$setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)].volume_usd)), 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('circulation')), 1),
              _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$filter.round(_ctx.wsAssets.data[$setup.GRemoveSuffix(_ctx.selectedSymbol.spot.asset)].circulation_usd)), 1)
            ])
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(5, (key) => {
            return _createElementVNode("div", {
              class: "child",
              key: key
            }, [
              (key == 1)
                ? (_openBlock(), _createBlock(_component_Skeleton, {
                    key: 0,
                    width: "80%",
                    height: "35px",
                    class: "mb-2",
                    borderRadius: "5px"
                  }))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(2, (item) => {
                    return _createVNode(_component_Skeleton, {
                      key: item,
                      width: "80%",
                      height: "20px",
                      class: _normalizeClass(item == 1 ? 'mb-3':''),
                      borderRadius: "5px"
                    }, null, 8, ["class"])
                  }), 64))
            ])
          }), 64))
    ])
  ]))
}