import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-chart mb15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueTradingView = _resolveComponent("VueTradingView")!
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ((_ctx.selectedSymbol && _ctx.selectedSymbol.spot && _ctx.showChart))
      ? (_openBlock(), _createBlock(_component_VueTradingView, {
          key: 0,
          options: $setup.getOptions()
        }, null, 8, ["options"]))
      : (_openBlock(), _createBlock(_component_Skeleton, {
          key: 1,
          width: $props.width ? $props.width : '100%',
          height: $props.height ? $props.height :'360px',
          borderRadius: "8px"
        }, null, 8, ["width", "height"]))
  ]))
}