
import { AccountRepository } from "@/core/repository/account.repository";
import store from "@/core/store";
import { getImage } from "@/core/helper/getImage";
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { TradeRepository } from "@/core/repository/trade.repository";
import { ToastService } from "@/core/service/toast.service";
import { i18n } from "@/main";
import EmptyBox from "@/app/unit/shared/emptyBox/emptyBox.vue";
export default defineComponent({
  name: "locked",
  setup(props, ctx) {
    const state = reactive({
      loading: false,
      loadingCancel: false,
      lockedData: [] as lockedData[],
    });
    const getLockedWallet = () => {
      state.loading = true;
      AccountRepository.getInstance().Getlocked({
        onReceive(res, headers) {
          state.lockedData = res;
          state.loading = false;
        },
      });
    };
    const cancelTrade = (data: any) => {
      TradeRepository.getInstance().CancelSingleOrder({
        body: {
          orderid: data._id,
          market: data.market,
        },
        onLoadChange: (load) => (state.loadingCancel = load),
        onReceive: (res) => {
          ToastService.getInstance().success(
            `${i18n.global.tc("cancelOrder")} ${i18n.global.tc("Success")}`
          );
          getLockedWallet();
        },
      });
    };
    onMounted(() => {
      getLockedWallet();
    });
    return {
      ...toRefs(state),
      getLockedWallet,
      getImage,
      cancelTrade,
    };
  },
  components: { EmptyBox },
});

interface lockedData {
  amount: number;
  avgprice: number;
  canceled: boolean;
  completed: boolean;
  createdAt: string;
  date: string;
  fee: number;
  isbuy: boolean;
  locked: number;
  market: string;
  price: number;
  processamount: number;
  tradeid: string;
  type: string;
  updatedAt: string;
  user: string;
  volume: number;
  _id: string;
}
[];
