import { assetsModel, WsAssetsModel } from "@/data/assets.model";
import { UserInfoModel } from "@/data/user.model";
import { createStore } from "vuex";
import { AccountRepository } from "../repository/account.repository";
import { AuthRepository } from "../repository/auth.repository";
import { TradeModule } from "./trade.module";
import { FuturesModule } from "./futures.module";
import { IsLegginged, GRemoveSuffix } from "./../service/utils.service";
import { WsService } from "../service/WebSocketServiece";
import { wsStore } from "./webSoket.store";

export var _interval = null;
const store = createStore<StoreInterface>({
  state: {
    theme: true,
    userDetails: null,
    selectedSymbol: {
      assets: <assetsModel>null,
      spot: <assetsModel>null,
    },
    assets: {
      list: <assetsModel[]>[],
      loading: false,
    },
    checkSelectedLoading: false,
    wsAssets: {
      list: <WsAssetsModel[]>[],
      loading: false,
      data: null,
      marketList: <WsAssetsModel[]>[],
    },
    averagePrice: 0,
    orderBooks: null,
    selectedOrderBookPrice: 0,
    tradeDetails: {
      volume: null,
      isBuy: null,
    },
    selectedFuturesDetails: {
      symbol: null,
      leverage: null,
      orderBooks: null,
      tradeDetails: {
        volume: null,
        isBuy: null,
      },
    },
  },
  mutations: {
    changeTheme(state, payload) {
      state.theme = !payload;
    },
    logout(state) {
      state.userDetails = null;
      localStorage.clear();
      window.location.href = "/#/login";
    },
    async getUserDetails(state): Promise<UserInfoModel> {
      const token = localStorage.getItem("token");
      if (!!!token) return null;
      AuthRepository.getInstance().GetAccountInfo({
        onReceive: (res: UserInfoModel) => {
          state.userDetails = res;
          return res;
        },
        onError: (err) => {
          // if (err.msg === "TOTP Not Logined") return null;
          //FIXME: باید چک بشه توکن اوکیه یا نه اگه نه همین جا رفرش کنه و دوباره اطلاعات کاربر رو بگیره
        },
      });
    },
    async updateUserLevel(state, payload): Promise<UserInfoModel> {
      const token = localStorage.getItem("token");
      if (!!!token) return null;
    },
    async getAllAssets(state) {
      const token = localStorage.getItem("token");
      if (!!token) {
        AccountRepository.getInstance().GetAllAssets({
          onReceive: (res) => {
            console.log("hrere");
            state.assets.list = [];
            Object.keys(res).forEach((key) => {
              const item: assetsModel = {
                chain: res[key].chain,
                asset: res[key].asset,
                can_deposit: res[key].can_deposit,
                can_withdraw: res[key].can_withdraw,
                deposit_least_amount: res[key].deposit_least_amount,
                withdraw_least_amount: res[key].withdraw_least_amount,
                withdraw_tx_fee: res[key].withdraw_tx_fee,
                withdrawal_precision: res[key].withdrawal_precision,
              };

              state.assets.list.push(item);
            });

            state.selectedSymbol.spot = state.assets.list.find(
              (item) => item.asset.toUpperCase() == "BTC"
            );
          },
          onLoadChange: (load) => (state.assets.loading = load),
        });
      }
    },

    closeWsConnection(state, payload) {
      switch (payload.data) {
        case "wsList":
          // state.wsAssetListService.closeConnection();
          break;
        case "wsMarket":
          WsService.getInstance().closeConnection();
          break;
        default:
          break;
      }
    },
    onChangeSelectedSymbol(state, payload) {
      if (payload.isFutures) {
        state.selectedFuturesDetails.symbol = payload.data;
        state.averagePrice = 0;
        console.log("payload.isFutures");

        state.selectedFuturesDetails.orderBooks = null;
      } else {
        if (payload.isAssets) {
          state.selectedSymbol.assets = payload.data;
        } else {
          state.selectedSymbol.spot = payload.data;
        }
        state.averagePrice = 0;
        console.log("set nall ");
        state.orderBooks = null;
      }
    },
    setOrderBook(state, payload) {
      if (payload.isFutures) {
        state.orderBooks = null;
        state.selectedFuturesDetails.orderBooks = payload.orderBook;
      } else {
        state.selectedFuturesDetails.orderBooks = null;
        state.orderBooks = payload.orderBook;
      }
      state.averagePrice = payload.averagePrice;
    },

    setMarketPrice(state) {
      wsStore().connect({ event: "market" });
      if (!!_interval) clearInterval(_interval);
      _interval = setInterval(() => {
        wsStore().sendData({ event: "market" });
      }, 3000);

      wsStore().$subscribe((mutation, state) => {
        onChangeMarket(state.wsData);
      });

      const onChangeMarket = (Data) => {
        if (Data && Data.event == "market" && Data.data && Data.data?.BTC) {
          const wsData = Data.data;

          state.wsAssets.data = wsData;
          if (state.wsAssets.marketList.length == 0) {
            Object.keys(wsData).forEach((item) => {
              if (wsData[item]) {
                state.wsAssets.marketList.push(wsData[item]);
              }
            });
          } else {
            state.wsAssets.marketList = [];
            Object.keys(wsData).forEach((item) => {
              if (wsData[item]) {
                state.wsAssets.marketList.push(wsData[item]);
              }
            });
          }

          if (
            !state.selectedSymbol.spot &&
            state.wsAssets.marketList.length > 0
          )
            state.selectedSymbol.spot = state.wsAssets.marketList.find(
              (item) => item.asset.toUpperCase() == "BTC"
            );

          if (state.wsAssets.marketList.length > 0) {
            state.wsAssets.marketList = state.wsAssets.marketList
              .map((item) => {
                if (!item) {
                } else {
                  try {
                    const key = item.asset
                      .replace("USDT", "")
                      .replace("USDC", "");
                    const name = item.asset;
                    if (!!wsData[item.asset == "USDCUSDT" ? "USDC" : key]) {
                      item = { ...wsData[key] };
                      item.asset = name;
                    }
                    if (!item) {
                      console.log(item);
                    } else {
                      return item;
                    }
                  } catch {}
                }
              })
              .sort((a, b) => a.circulation_usd_rank - b.circulation_usd_rank);
          }
          if (state.wsAssets.marketList.length > 0) {
            if (state.selectedSymbol && state.selectedSymbol.spot) {
              const name = state.selectedSymbol?.spot?.asset;
              let _wsData = {
                ...wsData[GRemoveSuffix(state?.selectedSymbol?.spot?.asset)],
              };
              _wsData.asset = name;
              state.selectedSymbol.spot = { ..._wsData };
            }
            state.wsAssets.marketList = state.wsAssets.marketList.map(
              (item) => {
                item = wsData[GRemoveSuffix(item.asset)];
                if (!item) {
                  console.log(item);
                } else {
                  return item;
                }
              }
            );
          }
        }
      };
    },
    setWsAssets(state) {
      if (!IsLegginged) return;
    },
    setFuturesDetails(state, payload) {},
    setLoading(state, payload) {
      state.checkSelectedLoading = payload;
    },
  },
  actions: {
    async getMarketPrice({ state, dispatch, commit }) {
      if (state.wsAssets.marketList.length == 0) {
        commit("setWsAssets");
        commit("setMarketPrice");
      } else {
        commit("setMarketPrice");
      }
    },
    setTradeVolume(store, payload) {
      if (payload.isFutures) {
        store.state.selectedFuturesDetails.tradeDetails = payload;
      } else {
        store.state.tradeDetails = payload;
      }
    },
  },
  modules: {
    TradeModule,
    FuturesModule,
  },
});

export default store;

export interface SelectedSymbol {
  assets?: assetsModel;
  spot?: assetsModel | WsAssetsModel;
}

export interface Asset {
  list: assetsModel[];
  loading: boolean;
}

export interface WsAsset {
  list: WsAssetsModel[];
  loading: boolean;
  data?: any;
  marketList: WsAssetsModel[];
}

export interface TradeDetail {
  volume?: any;
  isBuy?: any;
}

export interface TradeDetail {
  volume?: any;
  isBuy?: any;
}

export interface SelectedFuturesDetail {
  symbol?: any;
  leverage?: any;
  orderBooks?: any;
  tradeDetails: TradeDetail;
}

export interface SpotBalance {
  _id?: string;
  symbol: string;
  balance: number;
}
export interface WsSpotBalance {
  _id?: string;
  user: string;
  symbol: string;
  balance: number;
  active: boolean;
  date: string;
  __v: number;
}

export interface StoreInterface {
  theme: boolean;
  userDetails?: UserInfoModel;
  selectedSymbol: SelectedSymbol;
  assets: Asset;
  checkSelectedLoading: boolean;
  wsAssets: WsAsset;
  averagePrice: number;
  orderBooks?: any;
  selectedOrderBookPrice: number;
  tradeDetails: TradeDetail;
  selectedFuturesDetails: SelectedFuturesDetail;
}
