export enum LanguageType {
    en = 'en',
    fa = 'fa',
    ar = 'ar',
    tr = 'tr',
    ku = 'ku'
}


export enum EnumKeys {
    KeyIsSupporter = 'isSupporter',
    KeyToken = 'token',
    KeyRefreshToken = 'refreshtoken',
    KeyWorkPages = 'workPages',
    KeyMainPage = 'mainPage',
    KeyLanguage = 'language',
    KeyeslectedPageId = 'selectedPageId',
    KeyChangeIndexes = 'changeIndexes',
    KeyCountries = 'countries',
    KeyProvinces = 'provinces',
    KeyCities = 'cities',
}

export enum SpotErrorEnum {
    "Succeeded"= 0,
    "Error"= 1,
    "Invalid argument"= 2,
    "Internal error"= 3,
    "IP prohibited"= 23,
    "AccessID does not exist"= 24,
    "Signature error"= 25,
    "AccessID expired"= 34,
    "Service unavailable"= 35,
    "Service timeout"= 36,
    "Main account and sub-account do not match"= 40,
    "The transfer to the sub-account was rejected"= 49,
    "Insufficient balance"= 107,
    "No permission to use this API"= 158,
    "Requests submitted too frequently"= 213,
    "The timestamp is wrong, the timestamp must be within ±60s of the server time"= 227,
    "Order number does not exist"= 600,
    "Other users’ orders"= 601,
    "Below the minimum buying or selling limit"= 602,
    "The order price deviates too much from the latest transaction price"= 606,
    "Merge depth error"= 651,
    "Service busy, please try again later."= 3008,
}