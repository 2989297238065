import {Constant} from '../constant/index';
import { Repository_Alt , ReturnResponse } from './repository_alt';

export class SwapRepository extends Repository_Alt {
  private static instance: SwapRepository
  public baseUrl = Constant.BASE_URL + 'swaps/'

  public static getInstance(): SwapRepository {
    if (!SwapRepository.instance) SwapRepository.instance = new SwapRepository()
    return SwapRepository.instance
  }

  public Swap(data: ReturnResponse) {
    data.url = `${this.baseUrl}`;
    this.post(data)
  } 

  public GetSwapHistory(data: ReturnResponse) {
    data.url = `${this.baseUrl}history?${data.urlContent.url}`;
    this.get(data)
  } 
}
