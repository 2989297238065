
import { Constant } from '@/core/constant'
import LazyList from 'lazy-load-list/vue'
import { ref } from 'vue-demi'

export default {
    props:['list' , 'loading'],
    emits:['onSelect' , 'onHide'],
    components:{
        LazyList
    },
     setup () {
        const filterInput = ref('')
        const onErrorImage = (e) => {
            e.target.src = Constant.ErrorImageUrl
        }

          return {
              onErrorImage,
              filterInput
          }
    }
  
}
