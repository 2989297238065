import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d4973ff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "empty-box-controller" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "gary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.LightOnly)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: require(`@/asset/img/icons/light-empty.png`),
          alt: "",
          srcset: ""
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.$store.state.theme)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: require(`@/asset/img/icons/light-empty.png`),
                alt: "",
                srcset: ""
              }, null, 8, _hoisted_3))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: require(`@/asset/img/icons/dark-empty.png`),
                alt: "",
                srcset: ""
              }, null, 8, _hoisted_4))
        ], 64)),
    _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.$t('noRecordsFound')), 1)
  ]))
}