
import { watch, ref, onMounted, reactive, toRefs, computed } from "vue";
import Tooltip from "primevue/tooltip";
import TabMenu from "primevue/tabmenu";
import OverlayPanel from "primevue/overlaypanel";
import { LanguageType } from "@/core/enum";
import {
  changeAppDirection,
  changeTheme,
  getLanguageFromCache,
} from "@/core/service/utils.service";
import Sidebar from "primevue/sidebar";
import { i18n } from "@/main";
import Menu from "primevue/menu";
import InputSwitch from "primevue/inputswitch";
import MegaMenu from "primevue/megamenu";
import store from "@/core/store";
import ProfileMenuComponent from "../profileMenu/ProfileMenuComponent.vue";
import AssetsMenuComponent from "../profileMenu/assetsMenuComponent.vue";
import { mapState } from "vuex";
import { mapState as PiniaState } from "pinia";
import { profileStore } from "@/core/store/profile.store";
import Divider from "primevue/divider";
import { ToastService } from "@/core/service/toast.service";
export default {
  computed: {
    ...mapState(["theme"]),
    ...PiniaState(profileStore, ["notifyLoading", "notifications"]),
  },
  directives: {
    tooltip: Tooltip,
  },
  components: {
    OverlayPanel,
    TabMenu,
    Sidebar,
    Menu,
    InputSwitch,
    MegaMenu,
    ProfileMenuComponent,
    AssetsMenuComponent,
    Divider,
  },
  setup() {
    const state = reactive({
      darkMode: false,
      items: null,
      sidebarShow: false,
      selectedLanguage: null,
      megaMenu: null,
      mobileMenu: [
        {
          label: i18n.global.tc("home"),
          icon: "pi pi-home",
          to: "/home",
        },
        {
          label: i18n.global.tc("market"),
          icon: "pi pi-shopping-bag",
          to: "/market",
        },
        {
          label: i18n.global.tc("spot"),
          icon: "pi pi-chart-line",
          to: "/spotTrade",
        },
        {
          label: i18n.global.tc("swap"),
          icon: "pi pi-sort-alt",
          to: "/swap",
        },
        {
          label: i18n.global.tc("marketView"),
          icon: "pi pi-microsoft",
          to: "/marketView",
        },
      ],
      tvMegaMenu: null,
      loadingProfile: false,
    });
    const opNotify = ref();

    const opLoginOrRegister = ref();
    const opAssets = ref();
    const opLanguages = ref();
    const tradeMenu = ref();
    const tvMenu = ref();

    const showLoginOrRegister = (event) => {
      opLoginOrRegister.value.toggle(event);
    };
    const showAssetsMenu = (event) => {
      opAssets.value.toggle(event);
    };

    const showLanguages = (event) => {
      opLanguages.value.toggle(event);
    };

    const hideMenu = () => {
      opLoginOrRegister.value.hide();
      opAssets.value.hide();
    };

    const showMenu = (event, key) => {
      switch (key) {
        case "tv":
          tvMenu.value.toggle(event);

          break;
        case "trade":
          tradeMenu.value.toggle(event);
          break;

        default:
          break;
      }
    };

    function initNavBar() {
      state.items = [
        {
          label: i18n.global.tc("home"),
          icon: "pi pi-home",
          to: "/home",
        },
        {
          label: i18n.global.tc("market"),
          icon: "pi pi-shopping-bag",
          to: "/market",
        },
        {
          label: i18n.global.tc("spot"),
          icon: "pi pi-chart-line",
          to: "/spotTrade",
        },
        {
          label: i18n.global.tc("swap"),
          icon: "pi pi-sort-alt",

          disabled: false,
          separator: true,
          command: (e) => {
            ToastService.getInstance().info("Coming Soon");
          },
        },
      ];

      state.tvMegaMenu = [
        {
          items: [
            {
              label: i18n.global.tc("marketView"),
              icon: "pi pi-microsoft",
              to: "/marketView",
            },
          ],
        },
      ];
      state.megaMenu = [
        {
          items: [
            // {
            //     label: i18n.global.tc('swap'),
            //     icon: 'pi pi-arrows-h',
            // },
          ],
        },
      ];
    }

    const getUserDetails = async () => {
      state.loadingProfile = true;
      const req = await store.commit("getUserDetails");

      state.loadingProfile = false;
    };

    onMounted(() => {
      if (getLanguageFromCache() != null) {
        if (getLanguageFromCache() === LanguageType.en) {
          state.selectedLanguage = LanguageType.en;
        } else {
          state.selectedLanguage = getLanguageFromCache();
        }
      } else {
        state.selectedLanguage = LanguageType.en;
      }
      let htmlElement = document.documentElement;
      const theme = localStorage.getItem("theme");

      if (theme === "light") {
        htmlElement.setAttribute("theme", "light");
        state.darkMode = false;
      } else {
        htmlElement.setAttribute("theme", "dark");
        state.darkMode = true;
      }
      store.commit("changeTheme", state.darkMode);

      initNavBar();
      getUserDetails();

      store.dispatch("getMarketPrice");
      store.commit("getAllAssets");
    });

    const getLangName = computed(() => {
      let name = "";
      console.log(state.selectedLanguage);

      switch (state.selectedLanguage) {
        case LanguageType.fa:
          name = "Persian";
          break;
        case LanguageType.ar:
          name = "Arabic";
          break;
        case LanguageType.tr:
          name = "Turkish";
          break;
        case LanguageType.ku:
          name = "Kurdish";
          break;

        default:
          name = "English";
          break;
      }
      console.log("name", name);

      return name;
    });

    watch(
      () => state.darkMode,
      (first) => {
        changeTheme(first);
        store.commit("changeTheme", state.darkMode);
      }
    );

    watch(
      () => state.selectedLanguage,
      (first) => {
        i18n.global.locale = first;
        opLanguages.value.hide();
        initNavBar();
        changeAppDirection(first);
      }
    );
    const toggleNotify = (event) => {
      opNotify.value.toggle(event);
      profileStore().seen = true;
    };
    return {
      opLoginOrRegister,
      showLoginOrRegister,
      opLanguages,
      showLanguages,
      showMenu,
      tradeMenu,
      tvMenu,
      hideMenu,
      opAssets,
      showAssetsMenu,
      getLangName,
      opNotify,
      toggleNotify,
      ToastService,
      ...toRefs(state),
    };
  },
};
