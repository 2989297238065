export class Constant {
    // api.pageaccounting.uper1.ir
    // https://130.185.76.240/user/register

    public static BASE_URL = 'https://apicrypto.qfmarkets.com/api/';
    public static ErrorImageUrl = 'https://egiby.net/defualtCoin.png';
    public static ApiRefreshToken = 'https://apicrypto.qfmarkets.com/api/user/refresh';
    public static propsBaseUrl = 'ws://82.115.18.147:2023/popups';
    public static Ws_Base_Url = 'wss://apicrypto.qfmarkets.com/';
    public static Ws_GetBalance = 'ws://82.115.18.147:1010/balance';

    public static BASE_TAKE = 10;
    public static BASE_SKIP = 0;
    public static BASE_Sort = 1;
    public static Token_Key = "token";
    public static refreshToken = "refreshToken";
    public static lastRoute = 'lastRoute';
    //api productCategory category
    public static Port = 8080;
}