

export default {
    props:['tips' , 'status'],
    setup (props) {

        const getClassByStatus = (isIcon) => {
            switch (props.status) {
                case 'info':
                    return isIcon ? 'yellow' : 'gary'
                case 'danger':
                    return 'red'
                default:
                    break;
            }
        }
    
        return {
            getClassByStatus
        }
    }
}
