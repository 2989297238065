import { AccountRepository } from '@/core/repository/account.repository';
import { defineStore } from 'pinia';
import { SpotBalance } from '.';
import { WsService } from '../service/WebSocketServiece';
import { Constant } from '@/core/constant/index';
import { WsServiceBalance } from '../service/balanceWebSocketServiece';
import { WsSpotBalance } from './index';
import { EnumKeys } from '../enum';
import { WsServiceNotification } from '../service/webSocketNotification';

export const profileStore = defineStore('profileStore', {
    state: (): State => {
        return {
            toTpDialog:false,
            unBindToTpDialog:false,
            hasToTp:false,
            toTpQr:null,
            isBuy:true,
            notifications:[],
            notifyLoading:false,
            spotBalance:{
                list:[],
                obj:null,
                loading:false
            },
            seen:false
        }
    },
    actions:{
        getUserSpotBalance() {
            const store = profileStore();
            const token = localStorage.getItem('token'); 
            if (!token) return
            AccountRepository.getInstance().GetSpotBalance({
                onReceive(res:SpotBalance[]) {
                    console.log('res spotBalance' , res);
                    
                    store.spotBalance.list = [...res];
                    let obj  = new Map<string, number>([]);

                    res.forEach((item)=> {
                        obj.set(item.symbol.toLowerCase() , item.balance);
                    })
                    
                    store.spotBalance.obj = obj;
                }
            })
        },
        watchBalance() {
            const token = localStorage.getItem('token'); 
            if (!token) return
            const store = profileStore();
            WsServiceBalance.getInstance().connect((wsData:WsSpotBalance[])=> {
                let obj  = new Map<string, number>([]);

                wsData.forEach((item)=> {
                    obj.set(item.symbol.toLowerCase() , item.balance);
                })
                store.spotBalance.list.map((item) => {
                    item.balance = obj.get(item.symbol.toLowerCase());
                    return item
                })
                store.spotBalance.obj = obj;
            })
        },
        getNotification(){
              
              const token = localStorage.getItem(EnumKeys.KeyToken)
              if (!token) return
              const store = profileStore();
              AccountRepository.getInstance().GetNoSeenPopups({
                  onReceive:(res)=> {
                    store.notifications = res.messages;
                  },onLoadChange:(load)=> store.notifyLoading = load
              })
              const wsService = new WsServiceNotification(Constant.propsBaseUrl)
              wsService.connect((wsData)=> {
                if (wsData) {

                  if (!wsData?.event) {
                    
                    let _list = store.notifications as NotificationsModel[];
                    _list.push(wsData);
                    store.notifications = _list;
                  }
                }

              })
              
        },
        initDialog (key) {
            
            switch (key) {
                case 1: // totp
                    this.toTpDialog = !this.toTpDialog;
                    break;
                case 2: // mobile
                    this.unBindToTpDialog = !this.unBindToTpDialog;
                    break;
                default:
                    break;
            }
        },
        generateTotp() {
            this.toTpQr = null;
            AccountRepository.getInstance().GenerateTotp({
                onReceive:(res)=> {
                    this.toTpQr = res
                }
            })
        },
        async BindToTp( code):Promise<boolean> {
            const thisStore = profileStore()
            return new Promise(resolve => {
                AccountRepository.getInstance().BindToTp({
                    body:{
                        code
                    },onReceive:(res)=> {
                        resolve(true);
                        thisStore.initDialog(1);
                    },onError:(err) => {
                        resolve(false);
                    }
                })
            });
        },
        
    }
})

interface State {
    toTpDialog:boolean;
    unBindToTpDialog:boolean;
    hasToTp:boolean;
    toTpQr:any;
    notifications:NotificationsModel[];
    notifyLoading:boolean;
    spotBalance: {
        obj:Map<string,number>;
        list:SpotBalance[];
        loading:boolean;
    };
    isBuy:boolean;
    seen: boolean,
  }


  export interface NotificationsModel {
	_id: string;
	admin: string;
	sent: string;
	type: string;
	msg: string;
	title: string;
	date: string;
	__v: number;
  seen:boolean;
}