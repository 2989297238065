import moment from "jalali-moment";
import { i18n } from '@/main';

export class DateService {
    private static instance: DateService

    public static getInstance(): DateService {
        if (!DateService.instance) DateService.instance = new DateService()
        return DateService.instance
    }

    getCurrentJalaliMonth() {
        let m = moment(new Date().toISOString());
        return m.jMonth() + 1;
    }

    convertToJalali(date: string, withTime: boolean = false) {
        if (date == null || date == '' || !date) {
            return 'ندارد';
        }
        let text = '';
        try {
            let startdate = moment(date);
            text = startdate.format(withTime ? 'jYYYY/jMM/jDD HH:mm' : 'jYYYY/jMM/jDD');
        } catch (error) {
            text = 'تاریخ نامعتبر';
        }
        return text;
    }
    getJalaliTime(date: string) {
        if (date == null || date == '' || !date) {
            return 'ندارد';
        }
        let text = '';
        try {
            text = moment.from(date, 'MM/DD/YYYY').format('HH:mm');
        } catch (error) {
            text = 'زمان نامعتبر است';
        }
        return text == 'Invalid date' ? 'زمان نامعتبر است' : text;
    }

    timeNow() {
        let utc = moment.utc();
        return utc.format();
    }

    dayFromNow(date) {
        if (date == null) return '- 0 -'
        let m;
        m = moment(date);
        let today;
        today = moment().startOf('day');
        return Math.round(moment.duration(m - today).asDays());
    }

    timeDifference(previous) {
        previous = new Date(previous);
        let msPerMinute = 60 * 1000;
        let msPerHour = msPerMinute * 60;
        let msPerDay = msPerHour * 24;
        let msPerMonth = msPerDay * 30;
        let msPerYear = msPerDay * 365;
        let current = Date.now();
        let elapsed = current - previous;

        if (elapsed < 0) {
            return 'نامعتبر'
        } else if (elapsed < msPerMinute) {
            return Math.round(elapsed / 1000) + ' ' + i18n.global.tc('seconds-ago');
        } else if (elapsed < msPerHour) {
            return Math.round(elapsed / msPerMinute) + ' ' + i18n.global.tc('minutes-ago');
        } else if (elapsed < msPerDay) {
            return Math.round(elapsed / msPerHour) + ' ' + i18n.global.tc('hours-ago');
        } else if (elapsed < msPerMonth) {
            return Math.round(elapsed / msPerDay) + ' ' + i18n.global.tc('days-ago');
        } else if (elapsed < msPerYear) {
            return Math.round(elapsed / msPerMonth) + ' ' + i18n.global.tc('months-ago');
        } else {
            return Math.round(elapsed / msPerYear) + ' ' + i18n.global.tc('years-ago');
        }

    }
    dayOfweek(time) {
        let date = new Date(time);
        let weekday = [
            i18n.global.tc('saturday'),
            i18n.global.tc('sunday'),
            i18n.global.tc('monday'),
            i18n.global.tc('tuesday'),
            i18n.global.tc('wednesday'),
            i18n.global.tc('thursday'),
            i18n.global.tc('friday'),

        ]
        return weekday[date.getDay()];
    }
    convertDateToTimeZone(dateTime) {
        const sdate = moment.from(dateTime,
            'fa',
            'YYYY/MM/DD HH🇲🇲ss'
        );
        return sdate.toISOString();
    }

    countDown(date, onTick: Function, onStop: Function) {
        let countDownDate = new Date(date).getTime();
        let res
        if (res === undefined) {
            let now = new Date().getTime();
            let distance = countDownDate - now;
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            res = days + ' ' + i18n.global.tc('day') + ' ' + hours + ' ' + i18n.global.tc('hour') + ' ' + minutes + ' ' + i18n.global.tc('minute') + ' ' + seconds + ' ' + i18n.global.tc('second')
        }

        let interval = setInterval(function () {
            let now = new Date().getTime();
            let distance = countDownDate - now;
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0) {
                clearInterval(interval);
                res = 'زمان تموم شد';
                console.log('res 0 ', res);
                onStop()
            }
            else {
                res = days + ' ' + i18n.global.tc('day') + ' ' + hours + ' ' + i18n.global.tc('hour') + ' ' + minutes + ' ' + i18n.global.tc('minute') + ' ' + seconds + ' ' + i18n.global.tc('second')
                // console.log('res 1 ', res);
                onTick(res)
            }

        }, 1000);
        return res
    }

}