
import { Constant } from '@/core/constant'
import { GRemoveSuffix } from '@/core/service/utils.service'
import { reactive, toRefs } from 'vue-demi'
import { FilterMatchMode } from 'primevue/api'
import EmptyBox from '../shared/emptyBox/emptyBox.vue'

export default {
    props: {
        list: Array,
        show: Boolean,
        loading: Boolean,
        isSpot:Boolean
    },
    emits:['onSelect'],
    setup() {
        const state = reactive({
            filter: {
                "global": { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            selected:null
        });
        const onErrorImage = (e) => {
            e.target.src = Constant.ErrorImageUrl;
        };
        return {
            ...toRefs(state),
            onErrorImage
        };
    },
    components: { EmptyBox }
}
