import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

import Register from "@/app/unit/pages/auth/register/registerPageScreen.vue";
import Login from "@/app/unit/pages/auth/login/loginPageScreen.vue";
import Main from "@/app/unit/pages/main/mainPageScreen.vue";
import SpotTrade from "@/app/unit/pages/spotTrade/spotTradeScreen.vue";
import Assets from "@/app/unit/pages/assetsPage/assetsPageScreen.vue";
import SpotListComponent from "@/app/unit/pages/assetsPage/spotList/SpotListComponent.vue";
import locked from '@/app/unit/pages/assetsPage/locked/locked.vue';
import Header from "@/app/unit/shared/header/header.vue";
import ForgetPassword from "@/app/unit/pages/auth/forgetPassword/forgetPasswordPage.vue";
import Market from '@/app/unit/pages/market/marketPage.vue';
import { ToastService } from '../service/toast.service';
import { i18n } from './../../main';
import pageNotFoundScreenVue from '@/app/unit/pages/pageNotFound/pageNotFoundScreen.vue';
import tvScreenVue from '@/app/unit/pages/marketView/tvScreen.vue';
import MarketOverView from '@/app/unit/pages/marketView/marketOverViewScreen.vue';
import TransferScreenVue from '@/app/unit/pages/assetsPage/transfer/transferScreen.vue';
import assetsOverviewComponentVue from '@/app/unit/components/assetsOverviewComponent.vue';
import profileControllerVue from '@/app/unit/pages/profile/profileControllerScreen.vue';
import ProfileSetting from '@/app/unit/pages/profile/accountSetting/accountSettingScreen.vue';
import swapScreenVue from '@/app/unit/pages/swap/swapScreen.vue';
import TestScreenVue from '@/app/unit/pages/TestScreen.vue';
import StakeAssetsScreenVue from '@/app/unit/pages/assetsPage/stake/StakeAssetsScreen.vue';
import UsdWalletScreen from '@/app/unit/pages/assetsPage/usdWallet/usdWalletScreen.vue';
import CoinInfoScreenVue from '@/app/unit/pages/coinInfo/CoinInfoScreen.vue';
import showAllNotificationScreenVue from '@/app/unit/pages/showAllNotification/showAllNotificationScreen.vue';
import NoAccessComponent from '@/app/unit/shared/noAccess/NoAccessComponent.vue';
import LoginScreen from '@/app/unit/pages/login/loginScreen.vue';
import  AuthorizationScreen from "@/app/unit/pages/auth/AuthorizationScreen.vue";


const routes: Array<RouteRecordRaw> = [
  { path: "/showAllNotification", component: showAllNotificationScreenVue },
  {
    path: "/",
    component: Header,
    redirect: "/home",
    children: [
      { path: "/home", component: Main },
      { path: "/spotTrade", component: SpotTrade },
      { path: "/coinInfo/:id", component: CoinInfoScreenVue, props: true },
      {
        path: "/assets",
        component: Assets,
        redirect: "assets/overview",
        children: [
          {
            path: "/assets/overview",
            component: assetsOverviewComponentVue,
            beforeEnter: [canActivatedGuard],
          },
          {
            path: "/assets/spotList",
            component: SpotListComponent,
            beforeEnter: [canActivatedGuard],
          },
          {
            path: "/assets/locked",
            component: locked,
            beforeEnter: [canActivatedGuard],
          },
          {
            path: "/assets/stake",
            component: StakeAssetsScreenVue,
            beforeEnter: [canActivatedGuard],
          },
          {
            path: "/assets/usdWallet",
            component: UsdWalletScreen,
            beforeEnter: [canActivatedGuard],
          },
          {
            path: "/:catchAll(.*)",
            redirect: "/assets/overview",
          },
        ],
      },
      {
        path: "/profile",
        component: profileControllerVue,
        redirect: "profile/accountSetting",
        children: [
          {
            path: "/profile/accountSetting",
            component: ProfileSetting,
            beforeEnter: [canActivatedGuard],
          },
          {
            path: "/:catchAll(.*)",
            redirect: "/assets/accountSetting",
          },
        ],
      },
      { path: "/market", component: Market },
      {
        path: "/transfer",
        redirect: "transfer/deposit",
        component: TransferScreenVue,
        children: [],
      },
      { path: "/tv", component: tvScreenVue },
      { path: "/marketView", component: MarketOverView },
      { path: "/swap", component: swapScreenVue },
      { path: "/test", component: TestScreenVue },
      { path: "/noAccess", component: NoAccessComponent },
      { path: "/login", component: LoginScreen },
      { path: "/authentication", component: AuthorizationScreen },
      { path: "/authenticate", component: AuthorizationScreen },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: pageNotFoundScreenVue,
  },
];

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export function canActivatedGuard(to, from, next) {
	const token = localStorage.getItem('token');
    if (!!token) {
        next();
    } else {
		ToastService.getInstance().error(i18n.global.tc('noAccess'));
        router.push('/noAccess')
    }
}

export default router
