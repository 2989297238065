<template>
    <div>
        <iframe src="https://apicrypto.qfmarkets.com/marketview" width="100%" :height="height+'px'"></iframe>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
    name:"MarketOverView",
    setup () {
        const state = reactive({
            height: window.innerHeight - 65,
        })
    
        return {
            ...toRefs(state),
        }
    }
}
</script>

<style lang="scss" scoped>

</style>