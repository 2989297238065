<template>
    <div>
        <RegisterPageScreen />
    </div>
</template>

<script>
    import RegisterPageScreen from '@/app/unit/components/ResultFuturesComponent.vue'
    export default {
        components:{
            RegisterPageScreen
        }
        
    }
</script>

<style lang="scss" scoped>

</style>