import { createApp } from "vue";
import App from "./app/App.vue";
import router from "@/core/router/router";
import { createI18n } from "vue-i18n";
import en from "./translation/en.json";
import fa from "./translation/fa.json";
import ar from "./translation/arabic.json";
import tr from "./translation/turkish.json";
import ku from "./translation/kurdish.json";
import { LanguageType } from "./core/enum";
import { getLanguageFromCache } from "./core/service/utils.service";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import { VueQueryPlugin } from "vue-query";
import { createPinia } from "pinia";
///////////////////////////////// prime
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import PTooltip from "primevue/tooltip";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Dropdown from "primevue/dropdown";
import OverlayPanel from "primevue/overlaypanel";
import InputText from "primevue/inputtext";
import TriStateCheckbox from "primevue/tristatecheckbox";
import InputSwitch from "primevue/inputswitch";
import Calender from "primevue/calendar";
import Dialog from "primevue/dialog";
import Checkbox from "primevue/checkbox";
import InputNumber from "primevue/inputnumber";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "primevue/confirmpopup";
import Particles from "particles.vue3";
import QrReader from "vue3-qr-reader";
import VueApexCharts from "vue3-apexcharts";
import VueClipboard from "vue3-clipboard";
import Skeleton from "primevue/skeleton";
import lazyPlugin from "vue3-lazy";
import store from "./core/store";

import Paginator from "primevue/paginator";
////////////////////// recaptcha
//////////////////////////////////////

import "@/asset/css/darkTheme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "@/asset/css/bootstrap.css";
import "@/asset/css/fontawesome.css";
import "@/asset/css/primeCustom.css";
import "@/asset/css/iziToast.min.css";
import "@/asset/css/styles.scss";
import "swiper/swiper-bundle.css";

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
const pinia = createPinia();
import { DataFormatService } from "./core/service/dataFormat.service";

export const i18n = createI18n({
  messages: {
    en,
    fa,
    tr,
    ku,
    ar,
  },
  fallbackLocale: LanguageType.en,
  locale: getLanguageFromCache(),
});

export const application = createApp(App);
application.use(router);

application.config.globalProperties.$functions = {
  goTo(path) {
    router.push(path);
  },
  routerBack() {
    router.back();
  },
};

application.config.globalProperties.$filter = {
  currency(value) {
    return DataFormatService.getInstance().currency(value);
  },
  round(value) {
    return DataFormatService.getInstance().roundNumber(value);
  },
  jalli(value) {
    return DataFormatService.getInstance().convertToDate(value);
  },
  miladi(value) {
    return DataFormatService.getInstance().convertToMiladi(value);
  },
};

application.use(VueApexCharts);
application.use(Particles);
application.use(QrReader);
application.use(pinia);
application.use(store);
application.component("VueSlider", VueSlider);
application.component("Button", Button);
application.directive("tooltip", PTooltip);
application.component("DataTable", DataTable);
application.component("Column", Column);
application.use(lazyPlugin, {
  loading: "https://up-staticfiles.s3.ir-thr-at1.arvanstorage.com/loading.webp",
  error: "https://up-staticfiles.s3.ir-thr-at1.arvanstorage.com/failed.webp",
});
application.component("ColumnGroup", ColumnGroup);
application.component("Calender", Calender);
application.component("Dropdown", Dropdown);
application.component("InputText", InputText);
application.component("TriStateCheckbox", TriStateCheckbox);
application.component("OverlayPanel", OverlayPanel);
application.component("InputSwitch", InputSwitch);
application.component("Dialog", Dialog);
application.component("Checkbox", Checkbox);
application.component("InputNumber", InputNumber);
application.component("Skeleton", Skeleton);
application.component("ConfirmPopup", ConfirmPopup);
application.component("Swiper", Swiper);
application.component("SwiperSlide", SwiperSlide);
application.component("Paginator", Paginator);
application.use(PrimeVue, { ripple: true });
application.use(i18n);
application.use(ToastService);
application.use(ConfirmationService);
application.use(VueQueryPlugin);
application.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
});
//application.use(VueReCaptcha, { siteKey: '6LeBkukaAAAAAKRm-wb4NNUVSRobtamvVIzA5lYD' })
application.mount("#app");
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
