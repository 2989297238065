import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "market-trade mt-2 d-none d-lg-block overflow-hidden" }
const _hoisted_2 = {
  class: "nav nav-tabs",
  id: "myTab",
  role: "tablist"
}
const _hoisted_3 = {
  class: "tab-content",
  id: "myTabContent"
}
const _hoisted_4 = {
  class: "tab-pane fade show active",
  id: "limit",
  role: "tabpanel",
  "aria-labelledby": "limit-tab"
}
const _hoisted_5 = { class: "d-flex justify-content-between h-100 align-items-center" }
const _hoisted_6 = { class: "market-trade mobile d-flex d-lg-none" }
const _hoisted_7 = { class: "w-100 mb-2" }
const _hoisted_8 = { class: "w-100 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_BalanceDetailsComponent = _resolveComponent("BalanceDetailsComponent")!
  const _component_no_access_component = _resolveComponent("no-access-component")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "nav-item",
            role: "presentation",
            key: item.key
          }, [
            _createVNode(_component_Button, {
              onClick: ($event: any) => ($setup.onChangeTab(item.key)),
              label: item.label,
              class: _normalizeClass(["nav-link", _ctx.selectedType == item.key && 'active']),
              id: "limit-tab",
              "data-bs-toggle": "tab",
              "data-bs-target": "#limit",
              type: "button",
              role: "tab",
              "aria-controls": "limit",
              "aria-selected": "true"
            }, null, 8, ["onClick", "label", "class"])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.userDetails)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_BalanceDetailsComponent, {
                    maxDismal: _ctx.maxDismal,
                    selectedType: _ctx.selectedType,
                    isBuy: true,
                    loading: _ctx.buy.loading,
                    data: _ctx.buy.price,
                    onCreateOrder: _cache[0] || (_cache[0] = (values) => $setup.createOrder(true, values))
                  }, null, 8, ["maxDismal", "selectedType", "loading", "data"]),
                  _createVNode(_component_BalanceDetailsComponent, {
                    maxDismal: _ctx.maxDismal,
                    selectedType: _ctx.selectedType,
                    isBuy: false,
                    loading: _ctx.sell.loading,
                    data: _ctx.sell.price,
                    onCreateOrder: _cache[1] || (_cache[1] = (values) => $setup.createOrder(false, values))
                  }, null, 8, ["maxDismal", "selectedType", "loading", "data"])
                ], 64))
              : (_openBlock(), _createBlock(_component_no_access_component, { key: 1 }))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_TabView, null, {
        default: _withCtx(() => [
          _createVNode(_component_TabPanel, {
            header: "Buy",
            headerClass: "w-100"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.selectedType,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedType) = $event)),
                  options: _ctx.types,
                  optionLabel: "label",
                  optionValue: "key",
                  class: "w-100"
                }, null, 8, ["modelValue", "options"])
              ]),
              _createVNode(_component_BalanceDetailsComponent, {
                maxDismal: _ctx.maxDismal,
                selectedType: _ctx.selectedType,
                isBuy: true,
                isMobile: true,
                loading: _ctx.buy.loading,
                data: _ctx.buy.price,
                onCreateOrder: _cache[3] || (_cache[3] = (values) => $setup.createOrder(true, values))
              }, null, 8, ["maxDismal", "selectedType", "loading", "data"])
            ]),
            _: 1
          }),
          _createVNode(_component_TabPanel, {
            header: "Sell",
            headerClass: "w-100"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.selectedType,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedType) = $event)),
                  options: _ctx.types,
                  optionLabel: "label",
                  optionValue: "key",
                  class: "w-100"
                }, null, 8, ["modelValue", "options"])
              ]),
              _createVNode(_component_BalanceDetailsComponent, {
                maxDismal: _ctx.maxDismal,
                selectedType: _ctx.selectedType,
                isBuy: false,
                isMobile: true,
                loading: _ctx.sell.loading,
                data: _ctx.sell.price,
                onCreateOrder: _cache[5] || (_cache[5] = (values) => $setup.createOrder(false, values))
              }, null, 8, ["maxDismal", "selectedType", "loading", "data"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}