import iziToast from "izitoast";
import {isMobile} from "@/core/service/utils.service";

export class ToastService {
  private static instance: ToastService

  public static getInstance(): ToastService {
    if (!ToastService.instance) ToastService.instance = new ToastService()
    return ToastService.instance
  }

  private messages: string[] = []

  success(message: string) {
    if (this.messages.includes(message)) {
      return
    } else {
      this.messages.push(message)
    }
    setTimeout(() => {
      this.messages = []
    }, 2000)
    iziToast.show({
      message: message,
      color: 'green',

      timeout: 2000,
      position: isMobile.any() ? "topCenter" : "bottomRight",
    });
  }

  warning(message: string) {
    if (this.messages.includes(message)) {
      return
    } else {
      this.messages.push(message)
    }
    setTimeout(() => {
      this.messages = []
    }, 2000)
    iziToast.show({
      message: message,
      color: 'yellow', timeout: 2000,
      position: isMobile.any() ? "topCenter" : "bottomRight",
    });
  }

  error(message: string) {
    if (this.messages.includes(message)) {
      return
    } else {
      this.messages.push(message)
    }
    setTimeout(() => {
      this.messages = []
    }, 2000)
    iziToast.show({
      message: message,
      position: isMobile.any() ? "topCenter" : "bottomRight",
      color: 'rgb(231, 70, 70)',
      timeout: 2500,
      messageColor: 'white',
    });
  }

  info(message: string) {
    if (this.messages.includes(message)) {
      return
    } else {
      this.messages.push(message)
    }
    setTimeout(() => {
      this.messages = []
    }, 2000)
    iziToast.show({
      message: message,
      color: 'blue',
      timeout: 2000,
      position: isMobile.any() ? "topCenter" : "bottomRight",
    });
  }

}
