
import { onMounted, reactive, toRefs } from "vue";
import { i18n } from "@/main";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import VueApexCharts from "vue3-apexcharts";
import DialogCoinListComponent from "../../components/DialogCoinListComponent.vue";
import store from "@/core/store";
import router from "@/core/router/router";
import TransferComponent from "../../components/TransferComponent.vue";
import AssetsOverviewComponent from "../../components/assetsOverviewComponent.vue";
import { useRoute } from "vue-router";
export default {
  components: {
    Accordion,
    AccordionTab,
    apexcharts: VueApexCharts,
    DialogCoinListComponent,
    TransferComponent,
    AssetsOverviewComponent,
  },
  setup() {
    const state = reactive({
      sideList: [],
      activeSide: "/assets/overview",
      loading: false,
      transferType: 0,
      transferDialog: false,
      assets: [0, 0],
      assetsDialog: false,
      options: {
        // labels: ["spot","stake", "usd"],
        labels: ["spot", "usd"],
        dataLabels: {
          enabled: false,
        },
        dropShadow: {
          enabled: true,
          top: 0,
          left: 0,
          blur: 10,
          opacity: 1,
          color: "#212121",
        },
      },
    });

    // const childList = [
    //   {
    //     key: "1_0",
    //     label: i18n.global.tc("list"),
    //     to: "/assets/spotList",
    //   },
    // ];

    // const stakeChildList = [
    //   {
    //     key: "1_0",
    //     label: i18n.global.tc("list"),
    //     to: "/assets/stake",
    //   },
    // ];

    const initList = () => {
      state.sideList = [
        {
          label: i18n.global.tc("assets_overview"),
          icon: "i-total",
          to: "/assets/overview",
          path: "/assets/overview",
        },
        {
          label: i18n.global.tc("spot"),
          icon: "i-spot",
          to: "/assets/spotList",
          path: "/assets/spotList",
        },
        {
          label: i18n.global.tc("locked"),
          icon: "i-spot",
          to: "/assets/locked",
          path: "/assets/locked",
        },
        // {
        //   label: i18n.global.tc("stake"),
        //   icon: "i-making",
        //   to: "/assets/stake",
        //   path: "/assets/stake",
        // },
        {
          label: i18n.global.tc("usdWallet"),
          icon: "i-margin",
          to: "/assets/usdWallet",
          path: "/assets/usdWallet",
        },
      ];

      const route = useRoute();
      state.sideList.forEach((item) => {
        if (route.path == item.path) {
          state.activeSide = item.path;
        }
      });
    };
    //TODO: lazy bara kol safe

    const getAllAssets = async () => {
      if (store.state.assets && store.state.assets.list.length == 0) {
        store.commit("getAllAssets");
      }
    };

    const initTransfer = (key) => {
      state.transferType = key;
      if (key == 3) {
        state.transferDialog = true;
      } else {
        state.assetsDialog = true;
      }
    };

    const onSelectCoin = (data) => {
      const payload = {
        data,
        isAssets: true,
      };
      store.commit("onChangeSelectedSymbol", { ...payload });
      state.assetsDialog = false;
    };

    onMounted(() => {
      initList();
      getAllAssets();
    });

    return {
      ...toRefs(state),
      onSelectCoin,
      initTransfer,
    };
  },
};
