
import { computed, onMounted, reactive, ref, watch } from "vue";
import {
  changeAppDirection,
  changeTheme,
  getLanguageFromCache,
} from "@/core/service/utils.service";
import { LanguageType } from "@/core/enum";
import { i18n } from "@/main";
import OverlayPanel from "primevue/overlaypanel";
import { loginState } from "./loginState";
import InputNumber from "primevue/inputnumber";
import Password from "primevue/password";
import Checkbox from "primevue/checkbox";
import { phoneList } from "@/asset/phoneCode.json";
import { DataFormatService } from "@/core/service/dataFormat.service";
import VueFriendlyCaptcha from "@somushq/vue3-friendly-captcha";
import { ToastService } from "@/core/service/toast.service";
import store from "@/core/store";
import VOtpInput from "vue3-otp-input";
import Divider from "primevue/divider";
import { mapState } from "vuex";
import { useRoute } from "vue-router";
export default {
  components: {
    InputNumber,
    OverlayPanel,
    Password,
    Checkbox,
    VueFriendlyCaptcha,
    VOtpInput,
    Divider,
  },
  computed: {
    ...mapState(["theme"]),
  },
  setup() {
    const opLanguages = ref();
    const darkMode = ref(false);
    const isEmail = ref(false);
    const isValid = ref(true);
    const state = ref(new loginState());
    const selectedLanguage = ref(null);
    const loading = ref(false);
    const phone = ref({
      selected: phoneList[0],
      list: phoneList,
      dialog: false,
      searchValue: "",
    });

    const checkEmailValid = (email) => {
      return DataFormatService.getInstance().validateEmail(email);
    };

    const getLangName = computed(() => {
      let name = "";
      console.log(selectedLanguage.value);

      switch (selectedLanguage.value) {
        case LanguageType.fa:
          name = "Persian";
          break;
        case LanguageType.ar:
          name = "Arabic";
          break;
        case LanguageType.tr:
          name = "Turkish";
          break;
        case LanguageType.ku:
          name = "Kurdish";
          break;

        default:
          name = "English";
          break;
      }
      console.log("name", name);

      return name;
    });
    const onFilterCode = () => {
      const searchValue = phone.value.searchValue;
      phone.value.list = phoneList.filter(
        (value) =>
          value.en.includes(searchValue) ||
          value.mobileCode.includes(searchValue)
      );
    };

    const showLanguages = (event) => {
      opLanguages.value?.toggle(event);
    };

    const route = useRoute();
    onMounted(() => {
      if (getLanguageFromCache() != null) {
        if (getLanguageFromCache() === LanguageType.en) {
          selectedLanguage.value = LanguageType.en;
        } else {
          selectedLanguage.value = LanguageType.fa;
        }
      } else {
        selectedLanguage.value = LanguageType.fa;
      }
      let htmlElement = document.documentElement;
      const theme = localStorage.getItem("theme");

      if (theme === "light") {
        htmlElement.setAttribute("theme", "light");
        darkMode.value = false;
      } else {
        htmlElement.setAttribute("theme", "dark");
        darkMode.value = true;
      }
      store.commit("changeTheme", darkMode.value);
      console.log(route);

      if (route.query.token) localStorage.clear();
      state.value.onLogin(String(route.query.token));
    });

    const getUrl = () => {
      const url: string = window.location.href;
      return url.replace("https://", "").replace("https://", "");
    };

    const handleOnChange = (value: string) => {
      console.log("OTP changed: ", value);
      state.value.totp.value = value;
    };

    watch(
      () => darkMode.value,
      (first) => {
        changeTheme(first);
      }
    );

    watch(
      () => selectedLanguage.value,
      (first) => {
        i18n.global.locale = first;
        opLanguages.value?.hide();
        changeAppDirection(first);
      }
    );

    return {
      showLanguages,
      selectedLanguage,
      opLanguages,
      state,
      darkMode,
      isEmail,
      phone,
      onFilterCode,
      checkEmailValid,
      isValid,
      getUrl,
      handleOnChange,
      getLangName,
    };
  },
};
