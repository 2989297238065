
import TabMenu from 'primevue/tabmenu';
import { onMounted, reactive, ref, toRefs } from 'vue'
import { i18n } from "@/main";
import OverlayPanel from 'primevue/overlaypanel';
import CoinListComponent from "@/app/unit/shared/coins/coinListComponent.vue";
import TipsComponent from "../../../shared/tips/tipsComponent.vue";
import { ToastService } from '@/core/service/toast.service';
import store from '@/core/store';
import { AccountRepository } from '@/core/repository/account.repository';
import { mapState, useStore } from 'vuex';
// TODO:NEED vuex to change Theme and lang
export default {
  name: 'Deposit',
  computed:{ ...mapState([
      'selectedSymbol',
      'assets',
  ])},
  components: {
    TabMenu,
    OverlayPanel,
    CoinListComponent,
    TipsComponent,
},
  setup () {
    const mainState = reactive({
      tabs: [],
      coins:[],
      dialogCoinList:false,
      selectedNetwork:null,
      wallets:[],
      selectedWallet:null,
      walletLoading:false,
      balance:{
        data:null,
        loading:false
      }
    });

    const coinsPanel = ref();
    const showCoins = (event) => (store.state.selectedSymbol) && coinsPanel.value.toggle(event);
   

    const initTabs = () => {
      mainState.tabs = [
      
      ]
    };

    const onSelectCoin = (data) => {
      
       const payload = {
        data ,
        isAssets:true
      }
      store.commit('onChangeSelectedSymbol' , {...payload});
      coinsPanel.value.hide()
      mainState.dialogCoinList = false;
      mainState.selectedNetwork = null;
      getWallets();
    }

    const getWallets = () => {
      mainState.wallets = [];
      mainState.walletLoading = true;
      AccountRepository.getInstance().GetWallets({
        onReceive:(res)=>{
          mainState.wallets.push(...res)
          
        
          const interval = setInterval(reTry, 1000);
          function reTry() {
              if((mainState.selectedNetwork == null && store.state.selectedSymbol.assets)) {
                stopTrying()
              }  else if(mainState.selectedNetwork) {
                stopTrying()
                mainState.walletLoading = false;
              }
          }
          function stopTrying() {
            clearInterval(interval);
          }
        },onLoadChange:(load) => mainState.walletLoading = load
      })
    }

    const onChangeNetwork = (net) => {
      
        mainState.selectedNetwork = net;
        const index = mainState.wallets.findIndex(item => item.network === mainState.selectedNetwork)
        
        if (index == -1) {
            createWallet();
          } else {
            mainState.selectedWallet =  mainState.wallets[index]
          }
    }

    const createWallet = () => {
      mainState.walletLoading = true;
      AccountRepository.getInstance().CreateWallet({
        body:{
          network:mainState.selectedNetwork
        },onReceive:(res)=> {
          
          mainState.selectedWallet = res;
          mainState.selectedNetwork = res.network;
          getWallets();
        },onError:(err)=> mainState.walletLoading = false
      })
    }

    onMounted(()=> {
        initTabs();
        getWallets();
    })


    const onCopy = () => {
      ToastService.getInstance().success(i18n.global.tc('copied'));
    }

    const notAvailable = () => {
      ToastService.getInstance().error('networkIsNotAvailable')
    }
    
    
  
    return {
      ...toRefs(mainState),
      coinsPanel,
      showCoins,
      onSelectCoin,
      onCopy,
      getWallets,
      notAvailable,
      createWallet,
      onChangeNetwork,
    }
  }
}
