
//@ts-ignore
import { LinearBackoff, Websocket, WebsocketEvents, WebsocketBuilder } from 'websocket-ts';
import { EnumKeys } from '../enum';

export class WsServiceNotification {
    url = '';
    ws: Websocket|any ;

    constructor(url:string ){ 
        this.url = url;
    }

    connect(wsData: any) {
        const token = localStorage.getItem(EnumKeys.KeyToken);
        this.ws = new WebsocketBuilder(this.url)
        .onOpen((i, ev) => {
            this.ws.send(JSON.stringify({event:"login",token}))
         })
        .onClose((i:any, ev:any) => { console.log("closed" , this.url) })
        .onError((i:any, ev:any) => { console.log("error" , this.url) })
        .onMessage((i:any, ev:any) => {
            if (ev && ev.data) {
                if (this.checkJson(String(ev.data))) {
                  const data = JSON.parse(ev.data);
                  if (!(!data || Object.keys(data).length === 0)) {
                    wsData(data)
                  }
                }
              }
        })
        .withBackoff(new LinearBackoff(0, 1000, 5000))
        .onRetry((i:any, ev:any) => { 
            
          i.send(JSON.stringify({token}));
          i.send(JSON.stringify({event:"login",token}))

          console.log("retry" , this.url) })
        .build();
    }

    closeConnection(){
        this.ws.removeEventListener(WebsocketEvents.open, null);
        this.ws.close();
    }

  checkJson(text:any) :boolean {
    if (/^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').
    replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
    replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
      return true
    }else{
      return false
    }
  }
}

export interface WebSocketServiceModel {
    connect:Function;
    closeConnection:Function;
}