
import { onMounted } from 'vue-demi';
import { EnumKeys } from '@/core/enum';
import { useRouter } from 'vue-router';
import { ToastService } from '@/core/service/toast.service';
export default {
    setup () {
        const router = useRouter();
        onMounted(() => {
            const token = localStorage.getItem(EnumKeys.KeyToken)
            if (!!token) {
                router.push('/spotTrade')
            } else {
                ToastService.getInstance().info('Login from QFMarkets.com')
                setTimeout(() => {
                    window.location.href = 'https://qfmarkets.com/';
                }, 2000);
            }
        })
        return {}
    }
}
