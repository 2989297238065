
import {LinearBackoff, Websocket, WebsocketBuilder, WebsocketEvents} from 'websocket-ts';
import { EnumKeys } from '../enum';
import { Constant } from './../constant/index';

export class WsServiceBalance {
  
    private static instance: WsServiceBalance

    public static getInstance(): WsServiceBalance {
        if (!WsServiceBalance.instance) WsServiceBalance.instance = new WsServiceBalance()
        return WsServiceBalance.instance
    }
    ws: Websocket ;
    url = Constant.Ws_GetBalance
    
    connect(wsData: any) {
      const token = localStorage.getItem(EnumKeys.KeyToken);
      
        this.ws = new WebsocketBuilder(this.url)
        .onOpen((i, ev) => {
           this.ws.send(JSON.stringify({token}))
        })
        .onClose((i, ev) => { console.log("closed" , this.url) })
        .onError((i, ev) => { console.log("error" , this.url) })
        .onMessage((i, ev) => {
            if (ev && ev.data) {
                if (this.checkJson(String(ev.data))) {
                  const data = JSON.parse(ev.data) as any[];
                  if ((data.length > 0)) {
                    wsData(data)
                  }
                }
              }
        })
        .withBackoff(new LinearBackoff(0, 1000, 5000))
        .onRetry((i, ev) => { 
          i.send(JSON.stringify({token}));
          console.log("retry" , this.url)
         }).build();
    }

    closeConnection(){
        this.ws.removeEventListener(WebsocketEvents.open, null);
        this.ws.close();
    }

  checkJson(text) :boolean {
    if (/^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').
    replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
    replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
      return true
    }else{
      return false
    }
  }
}

export interface WebSocketServiceModel {
    connect:Function;
    closeConnection:Function;
}