
import {LinearBackoff, Websocket, WebsocketBuilder, WebsocketEvents} from 'websocket-ts';
import { Constant } from '@/core/constant/index';

export class WsService {
  
    private static instance: WsService

    public static getInstance(): WsService {
        if (!WsService.instance) WsService.instance = new WsService()
        return WsService.instance
    }
    
    ws: Websocket ;
    url = `${Constant.Ws_Base_Url}/markets`;
    
    connect(wsData: any) {
      console.log('url' , this.url);
      
        this.ws = new WebsocketBuilder(this.url)
        .onOpen((i, ev) => { console.log("opened" , this.url) })
        .onClose((i, ev) => { console.log("closed" , this.url) })
        .onError((i, ev) => { console.log("error" , this.url) })
        .onMessage((i, ev) => {
            if (ev && ev.data) {
                if (this.checkJson(String(ev.data))) {
                  const data = JSON.parse(ev.data);
                  if (!(!data || Object.keys(data).length === 0)) {
                    wsData(data)
                  }
                }
              }
        })
        .withBackoff(new LinearBackoff(0, 1000, 5000))
        .onRetry((i, ev) => { console.log("retry" , this.url) })
        .build();
    }

    closeConnection(){
        this.ws.removeEventListener(WebsocketEvents.open, null);
        this.ws.close();
    }

  checkJson(text) :boolean {
    if (/^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').
    replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
    replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
      return true
    }else{
      return false
    }
  }
}

export interface WebSocketServiceModel {
    connect:Function;
    closeConnection:Function;
}