<template>
  <div>
    <div class="coin-list-controller">
      <div class="space-between">
           <div class="input-control" >
             <span class="p-input-icon-left w-100">
               <i class="pi pi-search" />
               <InputText type="text" v-model="filterInput" :placeholder="$t('search')"  />
             </span>
           </div>
            <Button @click="$emit('onHide')" icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain" />
         </div>
       <div class="dl list-coins" v-if="!!list && !loading" >
        <AssetsLazyList :list="list.filter((item) =>  item.asset.includes(filterInput.toUpperCase()))" @onSelect="(data)=> onSelect(data)" />
       </div>
        <div class="loading-controller" v-else>
          <span class="table-texts">{{ $t("loading") }}</span>
        </div>
    </div>
  </div>
</template>

<script>
import AssetsLazyList from '@/app/unit/components/AssetsLazyList.vue';
export default {
    props:['list','loading' , 'filteredList', 'showDialog' ],
    emits:['onHide'],
    components:{
      AssetsLazyList,
    },
    data() {
      return {
          filterInput:''
      }
    },
    methods:{
      onSelect(data){
        this.$emit('onSelect' , data)
      },
      currency(price) {
          return Intl.NumberFormat().format((Number(price)));
      },
    }
  }
</script>

<style lang="scss" scoped>
@mixin row{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.space-between {
  @include row;
  width: 100%;
  justify-content: space-between;
  h5 {
    margin-bottom: 0;
    display: flex;
    flex: 1;
  }
}


  .input-control {
    padding: 0 0 15px;
    background-color: transparent;
    input {
      font-size: 14px;
      border-radius: 8px;
      transition: 0.4s;
      background: transparent !important;
      color: var(--text-color);
      border-width: 2px;
      padding-top: 11px;
      &:hover,
      &:focus {
        border: 2px solid var(--blue-theme-hex) !important;
      }
    }
  }
.coin-list {
    max-height: 740px;
    overflow-y: auto;
  }


.coin-style {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  img {
    width: 32px;
    height: 32px;
  }
}

.p-datatable-scrollable .p-datatable-wrapper {
  direction: ltr;
}

.coin-list-controller {
  padding: 1.5rem 0 0;
  width: 100%;
  min-width: 350px;
}



.loading-controller {
  display: flex;
  width: 100%;
  padding: 1rem 0;
  position: relative;
}


</style>
