
import { onMounted, reactive, ref, toRefs } from 'vue'
import store from '@/core/store';
import OverlayPanel from 'primevue/overlaypanel';
import CoinListComponent from "@/app/unit/shared/coins/coinListComponent.vue";
import { mapState } from 'vuex';
import {mapState as piniaMapState } from 'pinia';
import { TradeRepository } from '@/core/repository/trade.repository';
import { ToastService } from '@/core/service/toast.service';
import { i18n } from '@/main';
import { MyStakeModel, StakeListModel } from '@/data/assets.model';
import { profileStore } from '@/core/store/profile.store';
import DialogSelectCoinTransfer from './DialogSelectCoinTransfer.vue';
import { AccountRepository } from '@/core/repository/account.repository';

export default {
    computed:{
      getBalance(){
        return (symbol:string) => {
          const  {obj} = profileStore().spotBalance;
          return obj?.has(symbol.toLowerCase()) ? obj.get(symbol.toLowerCase()) : 0
        }
      },
      ...piniaMapState(profileStore , ['spotBalance']),
       ...mapState([
      'selectedSymbol',
      'assets',
    ])},
    components:{
      OverlayPanel,
      CoinListComponent,
      DialogSelectCoinTransfer
    },
  
    setup (props , {emit}) {
        const state = reactive({
            isUsd:true,
            amount:null,
            loading:false,
            showCoins:false,
            balance:{
                data: new Map<string , number>([]) ,
                loading:false,
                total:0
            },
        });

        const store = profileStore();
        const getAvailable = () => {
          let available = 0;
          if (state.isUsd) {
             available = state.balance.data.get('usd');
          } else {
             available = state.balance.data.get('usdt');
          }
          
          return Number(available ?? 0).toFixed(3)
        }


        const getBalance =  () => {
           const token = localStorage.getItem('token'); 
            if (!(!!token)) return null;
          
          // profileStore().getUserSpotBalance();

          AccountRepository.getInstance().GetSpotBalance({
            onReceive:(res)=> {
                state.balance.data.clear();
                const usdt = res.find((item)=> item.symbol == 'usdt')?.balance ?? 0;
                const usd = res.find((item)=> item.symbol == 'usd')?.balance ?? 0;
                state.balance.data.set('usdt' , usdt)
                state.balance.data.set('usd' , usd)
                console.log('res' , res);
            },onLoadChange:(load)=> state.balance.loading = load
          })
        }

        const Transfer = () => {

          const {amount , isUsd} = state;
          TradeRepository.getInstance()[isUsd ? 'usdToUsdt' : 'usdtToUsd']({
            body:{amount}
            ,onReceive:(res)=> {
              state.amount = 0;
              ToastService.getInstance().success(`${i18n.global.tc('transfer')} ${i18n.global.tc('transferSuccessful')}`)
              getBalance();
              profileStore().getUserSpotBalance();
            },onLoadChange:(load) => state.loading = load
          })
        };

        onMounted(() => {
          getBalance();
        })

        return {
            ...toRefs(state),
            getAvailable,
            Transfer,
        }
    }
}
