
import { reactive, toRefs } from 'vue'
import { mapState } from 'vuex'
import { GRemoveSuffix , USDTORUSDC} from '@/core/service/utils.service'

export default {
    props:['isFuture'],
    computed: mapState([
        'wsAssets',
        'selectedFuturesDetails',
        'selectedSymbol'
    ]),
    setup () {
        const state = reactive({
            count: 0,
        })
        return {
            ...toRefs(state),
            GRemoveSuffix,
            USDTORUSDC
        }
    }
}
