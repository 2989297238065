
import { Constant } from '@/core/constant'
import LazyList from 'lazy-load-list/vue'
import { GRemoveSuffix } from '@/core/service/utils.service'

export default {
    props:['list' , 'isFutures' , 'justString' , 'isSwap'],
    emits:['onSelect'],
    components:{
        LazyList
    },
     setup () {
          const onErrorImage = (e) => {
              e.target.src = Constant.ErrorImageUrl;
              
          }


          return {
              onErrorImage,
              GRemoveSuffix
          }
    }
  
}
