import { Constant } from "../constant/index";
import { Repository, ReturnResponse } from "./repository";

export class AccountRepository extends Repository {
  private static instance: AccountRepository;
  public baseUrl = Constant.BASE_URL;
  public baseUrlMarket = Constant.BASE_URL + "market/";

  public static getInstance(): AccountRepository {
    if (!AccountRepository.instance)
      AccountRepository.instance = new AccountRepository();
    return AccountRepository.instance;
  }

  public GetNetworks(data: ReturnResponse) {
    data.url = `${this.baseUrl}networks`;
    this.get(data);
  }
  public GetAllAssets(data: ReturnResponse) {
    data.url = `${this.baseUrl}spot/allasset`;
    this.get(data);
  }
  public GetSpotBalance(data: ReturnResponse) {
    data.url = `${this.baseUrl}user/balance/spot`;
    this.get(data);
  }
  public GetWallets(data: ReturnResponse) {
    data.url = `${this.baseUrl}wallets`;
    this.get(data);
  }

  public Getlocked(data: ReturnResponse) {
    data.url = `${this.baseUrl}user/balance/locked`;
    this.get(data);
  }

  public CreateWallet(data: ReturnResponse) {
    data.url = `${this.baseUrl}wallet/create`;
    this.post(data);
  }
  public GetFutureMarkets(data: ReturnResponse) {
    data.url = `${this.baseUrlMarket}future/markets`;
    this.get(data);
  }

  public GenerateTotp(data: ReturnResponse) {
    data.url = `${this.baseUrl}user/totp/generate`;
    this.post(data);
  }
  public UnBindToTp(data: ReturnResponse) {
    data.url = `${this.baseUrl}user/totp/unbind`;
    this.post(data);
  }
  public BindToTp(data: ReturnResponse) {
    data.url = `${this.baseUrl}user/totp/bind`;
    this.post(data);
  }

  public LoginTotp(data: ReturnResponse) {
    data.url = `${this.baseUrl}user/totp/login`;
    this.post(data);
  }

  public GetCoinInfo(data: ReturnResponse) {
    data.url = `${this.baseUrl}spot/info?symbol=${data.urlContent.id}`;
    this.get(data);
  }
  public GetPopups(data: ReturnResponse) {
    data.url = `${this.baseUrl}user/popups?${data.query}`;
    this.get(data);
  }
  public GetNotificationMassage(data: ReturnResponse) {
    data.url = `${this.baseUrl}user/popups/msg?msgid=${data.query}`;
    this.get(data);
  }
  public GetNoSeenPopups(data: ReturnResponse) {
    data.url = `${this.baseUrl}user/popups/noseen`;
    this.get(data);
  }
}
