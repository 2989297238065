import axios from 'axios'
import {LocalStorageService} from "@/core/service/localStorage.service";
import {Constant} from '../constant';


let instance = axios.create({
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Cache-Control': 'no-cache',
    'accept': 'application/json'
  },
  timeout:60000
})

instance.interceptors.request.use(function (config) {
  const token = LocalStorageService.getInstance().getItem(Constant.Token_Key);
  const refresh = LocalStorageService.getInstance().getItem('refreshtoken');
  if ( config.url !== Constant.BASE_URL+ 'register' && !!token) {
    config.headers.token = config.url == Constant.ApiRefreshToken ? refresh : token;
  }


 
  return config;
});
export default instance;