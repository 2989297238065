import {Constant} from '../constant/index';
import {Repository, ReturnResponse} from './repository';

export class TradeRepository extends Repository {
  private static instance: TradeRepository
  public baseUrl = Constant.BASE_URL + 'trade/'
  public usdBaseUrl = Constant.BASE_URL + 'exchange/'
  public stakeBaseUrl = Constant.BASE_URL + 'stake/'

  public static getInstance(): TradeRepository {
    if (!TradeRepository.instance) TradeRepository.instance = new TradeRepository()
    return TradeRepository.instance
  }
  //spot start
  public CreateLimitOrderBuy(data: ReturnResponse) {
    data.url = `${this.baseUrl}limit/buy`;
    this.post(data)
  } 
  public CreateLimitOrderSell(data: ReturnResponse) {
    data.url = `${this.baseUrl}limit/sell`;
    this.post(data)
  } 
  public CreateBuyMarketOrder(data: ReturnResponse) {
    data.url = `${this.baseUrl}market/buy`;
    this.post(data)
  } 
  public CreateSellMarketOrder(data: ReturnResponse) {
    data.url = `${this.baseUrl}market/sell`;
    this.post(data)
  } 
  public CreateStopLimitOrder(data: ReturnResponse) {
    data.url = `${this.baseUrl}spot/stoplimit`;
    this.post(data)
  } 
  public CreateStopLimitMarketOrder(data: ReturnResponse) {
    data.url = `${this.baseUrl}spot/stoplimitmarket`;
    this.post(data)
  } 
  
  public GetPendingOrders(data: ReturnResponse) {
    data.url = `${this.baseUrl}pending?${data.query ? `market=${String(data.query).includes('USDT') ?data.query:data.query+'USDT' } `: ''}`;
    this.get(data)
  } 
  public CancelSingleOrder(data: ReturnResponse) {
    data.url = `${this.baseUrl}order/cancele`;
    this.post(data)
  } 
  public CancelAllOrder(data: ReturnResponse) {
    data.url = `${this.baseUrl}spot/cancelallorder`;
    this.post(data)
  } 
  public GetSpotHistory(data: ReturnResponse) {
    data.url = `${this.baseUrl}history?${data.query ? `&symbol=${data.query}`:''}`;
    this.get(data);
  } 
  //spot end

  public TransferSpotToFutures(data: ReturnResponse) {
    data.url = `${this.baseUrl}transfer/tofuture`;
    this.post(data)
  } 
  public TransferFuturesToSpot(data: ReturnResponse) {
    data.url = `${this.baseUrl}transfer/tospot`;
    this.post(data)
  } 
 //futures 
 public GetFuturesHistory(data: ReturnResponse) {
  data.url = `${this.baseUrl}history/future?market=${data.urlContent.market}&page=1`;
  this.get(data);
} 
  public GetFuturesPendingOrders(data: ReturnResponse) {
    data.url = `${this.baseUrl}future/pending?market=${data.urlContent.asset}`;
    this.get(data)
  } 

  public CreateFutureLimitOrder(data: ReturnResponse) {
    data.url = `${this.baseUrl}future/limit`;
    this.post(data)
  } 
  public CreateFutureLimitMarketOrder(data: ReturnResponse) {
    data.url = `${this.baseUrl}future/marketorder`;
    this.post(data)
  } 
  public CreateFutureStopLimitOrder(data: ReturnResponse) {
    data.url = `${this.baseUrl}future/stoplimit`;
    this.post(data)
  } 
  public CreateFutureStopLimitMarketOrder(data: ReturnResponse) {
    data.url = `${this.baseUrl}future/stoplimitmarket`;
    this.post(data)
  } 
  
  public GetFuturePendingOrders(data: ReturnResponse) {
    data.url = `${this.baseUrl}future/orders/pending?market=${data.urlContent.asset}`;
    this.get(data)
  } 
  public CancelFutureSingleOrder(data: ReturnResponse) {
    data.url = `${this.baseUrl}future/cancelorder`;
    this.post(data)
  } 
  public CancelFutureAllOrder(data: ReturnResponse) {
    data.url = `${this.baseUrl}future/cancelallorder`;
    this.post(data)
  } 
  public CloseFuturePosition(data: ReturnResponse) {
    data.url = `${this.baseUrl}future/closeposition`;
    this.post(data)
  } 
  public SetLeverage(data: ReturnResponse) {
    data.url = `${this.baseUrl}future/setlaverage`;
    this.post(data)
  } 
  public FuturesPositions(data: ReturnResponse) {
    data.url = `${this.baseUrl}future/positions?market=${data.urlContent.asset}`;
    this.get(data)
  } 

  // tp
  public SetTakeProfit(data: ReturnResponse) {
    data.url = `${this.baseUrl}future/takeprofit`;
    this.post(data)
  } 
  // sl
  public SetStopLoss(data: ReturnResponse) {
    data.url = `${this.baseUrl}future/stoploss`;
    this.post(data)
  } 

  //Future end

  //stake 
  public GetMyStakes(data: ReturnResponse) {
    data.url = `${this.stakeBaseUrl}mystakes`;
    this.get(data)
  } 
  
  public GetStakeAssets(data: ReturnResponse) {
    data.url = `${this.stakeBaseUrl}list`;
    this.get(data)
  }
  
  public AddStake(data: ReturnResponse) {
    data.url = `${this.stakeBaseUrl}add`;
    this.post(data)
  } 
  public RemoveStake(data: ReturnResponse) {
    data.url = `${this.stakeBaseUrl}remove`;
    this.post(data)
  } 

  public TransferFromStake(data: ReturnResponse) {
    data.url = `${this.stakeBaseUrl}transfer/fromstake`;
    this.post(data)
  } 
  // end stake
  // swap

  //end Swap
 
  public GetRecountTrade(data: ReturnResponse) {
    data.url = `${this.baseUrl}historypublic?symbol=${data.query} `;
    this.get(data)
  } 
  public usdtToUsd(data: ReturnResponse) {
    data.url = `${this.usdBaseUrl}usdt/usd`;
    this.post(data)
  } 
  public usdToUsdt(data: ReturnResponse) {
    data.url = `${this.usdBaseUrl}usd/usdt`;
    this.post(data)
  } 
  public GetHistoryUSD(data:ReturnResponse) {
    data.url = `${this.usdBaseUrl}history`;
    this.get(data)
  }
}
