
import { onMounted, reactive, toRefs } from 'vue'
import AssetsLazyList from '../../components/AssetsLazyList.vue'
export default {
    props: ["coins" , 'isFutures' , 'isSwap' , 'justString'],
    name: "coinListComponent",
    setup(props, { emit }) {
        const state = reactive({
            filterInput: "",
            coins: [],
            filterList: []
        });
        const onFilter = (value) => {
            if (!props.justString ) {
                state.filterList = state.coins.filter((item) => item[ props.isFutures ? 'stock' : 'asset'].includes(value.toUpperCase()));
            } else  state.filterList = state.coins.filter((item) => item.includes(value.toUpperCase()));
        };
        onMounted(() => {
            state.filterList.push(...props.coins);
            state.coins.push(...props.coins);
        });
        const onSelect = (data) => {
            emit("onChange", data);
        };
        return {
            ...toRefs(state),
            onFilter,
            onSelect
        };
    },
    components: { AssetsLazyList }
}
