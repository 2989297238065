import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d921e4d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container py-5" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToTpFormComponent = _resolveComponent("ToTpFormComponent")!
  const _component_unBindTotp = _resolveComponent("unBindTotp")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.userDetails && !_ctx.userDetails.istotp)
      ? (_openBlock(), _createBlock(_component_ToTpFormComponent, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_unBindTotp),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_view)
        ])
      ])
    ])
  ]))
}