
import { onMounted, reactive, toRefs } from 'vue'
import HomeMarket from '@/app/unit/components/homeMarket.vue';
import { HomeRepository } from '@/core/repository/home.repository';

export default {
  setup() {
    const state = reactive({
      count: 0,
      loading:false,
     symbols:0,
     users:0,
     volume:0
    });
    const handleScrollTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const goTo = (path: string) => {
      window.location.href = path
    }

    onMounted(() => {
      HomeRepository.getInstance().GetHomeData({
        onReceive(res, headers) {
            state.symbols = res.symbols;
            state.users = res.users
            state.volume = res.volume
        },
        onLoadChange(loadStatus) {
            state.loading = loadStatus
        },
      })
    })

    return {
      ...toRefs(state),
      handleScrollTop,
      goTo
    };
  },
  components: { HomeMarket }
}
