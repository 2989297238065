
import { TradeRepository } from "@/core/repository/trade.repository";
import { reactive, toRefs, onMounted, computed } from "vue";
import { useQuery } from "vue-query";
import store from "@/core/store";
import { DateService } from "@/core/service/date.service";
import { mapState } from "vuex";
import { Store } from "vuex";
import ProgressSpinner from "primevue/progressspinner";
import moment from "moment";
export default {
  components: {
    ProgressSpinner,
  },
  computed: {
    ...mapState(["selectedSymbol"]),
  },
  setup() {
    // const getRecountTrade = () => {
    //   return new Promise<MarketHistoryModel[]>((resolve) => {
    //     const symbol = store.state.selectedSymbol?.spot?.asset;

    //       TradeRepository.getInstance().GetRecountTrade({
    //         query:symbol ? symbol : 'btc',
    //         onReceive:(res)=> {
    //           console.log('GetRecountTrade',res);
    //           const list = res.history as MarketHistoryModel[];
    //           list.sort((a , b)=> b.time - a.time)
    //           resolve(list)
    //         }
    //       })
    //     })
    // }

    // const recount =  useQuery(["getRecountTrade" , ],
    //     getRecountTrade , {
    //       refetchOnWindowFocus:false,
    //       keepPreviousData:false,
    //       refetchOnMount:false,
    //       retry:3,
    //       refetchInterval:10000,
    // });

    // const onChangeMarket = () => {
    //   store.subscribe((mutation, state) => {
    //     if(mutation.type == 'onChangeSelectedSymbol'   ){
    //       recount.remove.value();
    //       recount.refetch.value();
    //     }
    //   })
    // }

    const convertDate = (date: any) => {
      return moment().format("h:mm");
    };

    const OrderList = computed(() => {
      const orderBook = store.state.orderBooks;
      if (orderBook) {
        let bids = orderBook.data.bid.map((item) => {
          return {
            isBuy: true,
            price: Number(item[0]),
            time: new Date().getTime(),
            size: item[1],
          };
        });
        let asks = orderBook.data.ask.map((item) => {
          return {
            isBuy: false,
            price: Number(item[0]),
            time: new Date().getTime(),
            size: item[1],
          };
        });

        let _list = [...bids, ...asks];
        _list.sort((a, b) => Number(b.size) - Number(a.size));
        return _list;
      } else return [];
    });

    return {
      // ...toRefs(recount),
      convertDate,
      OrderList,
    };
  },
};

export interface MarketHistoryModel {
  sequence: string;
  price: string;
  size: string;
  side: string;
  time: number;
}
