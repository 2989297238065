import { WebSocketService } from "../service/webSocket";
import { Constant } from "@/core/constant/index";
import { wsStore } from "./webSoket.store";

export var _interval = null;
export const TradeModule = {
  state: () => ({
    wsTrade: null,
    tradeVolume: null,
    itsClosed: false,
    tvHeight: "27.4rem",
  }),
  mutations: {
    changeTvHeight(state, payload) {
      state.tvHeight = payload;
    },
    connectWs(state, payload) {
      state.itsClosed = false;
      state.wsTrade = new WebSocketService(
        `${Constant.Ws_Base_Url}/spot?market=${
          String(payload).toUpperCase().includes("USDT")
            ? String(payload).toUpperCase()
            : String(payload).toUpperCase() + "USDT"
        }`
      );
    },
    updateHistoryList(state, payload) {},
  },
  actions: {
    // getOrderBooks({ state, commit, rootState }) {
    //   //   if (!!_interval) clearInterval(_interval);
    //   commit(
    //     "setOrderBook",
    //     {
    //       orderBook: null,
    //       averagePrice: null,
    //       isFutures: false,
    //     },
    //     { root: true }
    //   );

    //   const _symbol =
    //     rootState.selectedSymbol && rootState.selectedSymbol.spot
    //       ? rootState.selectedSymbol?.spot?.asset
    //           .replace("usdt", "")
    //           .toLowerCase()
    //       : "btc";
    //   // if(wsStore().selected!=_symbol){
    //   //     wsStore().webSocketController.close()
    //   // }

    //   wsStore().selected = _symbol;
    //   wsStore().connect({ event: "spot", symbol: _symbol });
    //   wsStore().sendData({ event: "spot", symbol: _symbol });

    //   // commit(
    //   //   "setOrderBook",
    //   //   {
    //   //     orderBook: state.wsDataOrderBook.data,
    //   //     averagePrice: 0,
    //   //     isFutures: false,
    //   //   },
    //   //   { root: true }
    //   // );
    //   //   _interval = setInterval(() => {
    //   //     wsStore().sendData({ event: "spot", symbol: _symbol });
    //   //   }, 3000);

    //   wsStore().$subscribe((mutation, state) => {
    //     if (
    //       state.wsDataOrderBook &&
    //       state.wsDataOrderBook?.event == "spot" &&
    //       state.wsDataOrderBook?.data &&
    //       state.selected.toUpperCase() ==
    //         state.wsDataOrderBook?.symbol.toUpperCase()
    //     ) {
    //       onGetOrders(state.wsDataOrderBook.data);
    //     }
    //   });

    //   let _counter = 0;
    //   const onGetOrders = (wsData: OrderBookInterFace) => {
    //     _counter += 1;
    //     if (_counter % 2 == 0) {
    //       if (!state.itsClosed) {
    //         if (wsData) {
    //           let askTotal = 0;
    //           let bidTotal = 0;

    //           let askAverage = 0;
    //           let bidAverage = 0;
    //           let orderBook: OrderBookInterFace = null;
    //           if (wsData.ask.length < 10 || wsData.bid.length < 10) return;
    //           orderBook = wsData;

    //           //start bid
    //           const len = orderBook.ask.length + orderBook.bid.length;
    //           orderBook.bid = orderBook.bid.map((item) => {
    //             bidAverage += Number(item[0]);
    //             bidTotal += Number(item[1]);
    //             item[2] = String(
    //               ((Number(item[1]) * 100) / bidTotal).toFixed(3)
    //             );
    //             item[3] = String(bidTotal.toFixed(2));
    //             return item;
    //           });
    //           orderBook.bid = orderBook.bid
    //             .slice(-13)
    //             .sort((a, b) => Number(a[0]) - Number(b[0]))
    //             .reverse();
    //           //end bid

    //           //start ask

    //           orderBook.ask = orderBook.ask.map((item) => {
    //             askAverage += Number(item[0]);
    //             askTotal += Number(item[1]);
    //             item[2] = String(
    //               ((Number(item[1]) * 100) / askTotal).toFixed(3)
    //             );
    //             item[3] = String(askTotal.toFixed(2));
    //             return item;
    //           });

    //           orderBook.ask = orderBook.ask
    //             .slice(0, 13)
    //             .sort((a, b) => Number(b[0]) - Number(a[0]));

    //           const averagePrice = (askAverage + bidAverage) / len;
    //           //end ask

    //           commit(
    //             "setOrderBook",
    //             {
    //               orderBook,
    //               averagePrice,
    //               isFutures: false,
    //             },
    //             { root: true }
    //           );
    //         }
    //       } else {
    //         if (state.wsTrade) {
    //           state.wsTrade.closeConnection();
    //           if (state.wsTrade) state.wsTrade = null;
    //         }
    //       }
    //     }
    //   };
    // },
    desConnectOrderBook({ state }) {
      if (state.wsTrade) {
        state.itsClosed = true;
        state.wsTrade.closeConnection();
      }
    },
    clearFields(state, payload) {},
    updateHistoryList(state, payload) {},
  },
};

interface OrderBookInterFace {
  ask: string[][];
  bid: string[][];
  last: string;
  time: number;
  checksum: number;
}
