import httpClientBase from "@/core/service/http.service";
import { i18n } from "@/main";
import { Constant } from '@/core/constant/index'
import { LocalStorageService } from '@/core/service/localStorage.service'
import { isLoginCheck, WindowLoading , GenerateString } from "../service/utils.service";
import { ToastService } from "../service/toast.service";
import { EnumKeys, SpotErrorEnum } from "../enum";

export class Repository_Alt {
    private isRefreshingToken = false;
    queue: ReturnResponse[] = [];

    private addToQueue(data: ReturnResponse) {
        if (!this.queue.find(m => m.key == data.key)) this.queue.push(data);
    }

    private removeFromQueue(data: ReturnResponse) {
        const req = this.queue.findIndex((m) => m.key === data.key);
        if (req !== -1) {
          this.queue.splice(req, 1);
        }
      }
      private afterRefreshToken() {
        
        for (let i = 0; i < this.queue.length; i++) {
          switch (this.queue[i].httpMethod) {
            case HttpMethod.get:
              this.get(this.queue[i]);
              break;
            case HttpMethod.post:
              this.post(this.queue[i]);
              break;
           
          }
        }
        this.isRefreshingToken = false;
      }

      private errorInRefreshToken() {
        localStorage.clear();
        this.queue = [];
        this.isRefreshingToken = false;
      }
    

    refreshToken () {
        const token = localStorage.getItem(EnumKeys.KeyRefreshToken);
        if (this.isRefreshingToken || !token ) return
        this.isRefreshingToken = true;
        ToastService.getInstance().info(i18n.global.tc('sessionUpdating'));

        const data: ReturnResponse = {
            url: Constant.ApiRefreshToken, 
            onReceive: (res) => {
              this.removeFromQueue(data);
              if (res.token && res.refresh) {
                const token = res.token;
                const refreshToken = res.refresh;
                localStorage.setItem(EnumKeys.KeyToken, token);
                localStorage.setItem(EnumKeys.KeyRefreshToken, refreshToken);
                this.afterRefreshToken();
              } else {
                this.errorInRefreshToken();
              }
            },
            onError: () => this.errorInRefreshToken()
          }
          this.post(data);
    }
    get(data: ReturnResponse) {
        data.onLoadChange?.call(true, true);
        if (!data.key) data.key = GenerateString(5);
        data.httpMethod = HttpMethod.get;
        this.addToQueue(data);
        httpClientBase.get(data.url ?? '')
            .then(value => value)
            .then(value => {
                data.onLoadChange?.call(false, false);
                if (this.validateResponse(value.data.data)) return data.onReceive?.call(null, value.data.data  , value.headers);
                else data.onError?.call(null, value.data)
            }).catch(e => {
                if (e.response) {
                    data.onError?.call(null, e.response.data)
                    this.sendError(e.response.data.code ,e.response.data.data);
                }
                this.networkErrorHandler(e, data)
            });
    }

    post(data: ReturnResponse) {
        data.onLoadChange?.call(true, true);
        if (!data.key) data.key = GenerateString(5);
        data.httpMethod = HttpMethod.get;
        this.addToQueue(data);
        httpClientBase.post(data.url ?? '', data.body)
            .then(value => value)
            .then(value => {
                data.onLoadChange?.call(false, false);
                if (this.validateResponse(value.data.data)) return data.onReceive?.call(null, value.data.data ? value.data : {}  , value.headers);
                else data.onError?.call(null, value.data)
            }).catch((e) => {
                if (e.response) {
                    data.onError?.call(null, e.response.data)
                    this.sendError(e.response.data.code ,e.response.data);
                }
                this.networkErrorHandler(e, data)
            });
    }

   

     validateResponse(res) {
        
        if (res.code < 0) {
            return false;
        }
        
        return true;
    }

    networkErrorHandler(e, data) {
        data.onLoadChange?.call(false, false);
        data.onError?.call(null, i18n.global.tc('serverError'))
        console.log(e.message + ' ')
        WindowLoading.value = false;
        if (e.message === 'Request failed with status code 402') {
            this.getNewToken()
        } else if (e.message === "Network Error") {
            ToastService.getInstance().error(i18n.global.tc('errorInternet'))
        } else if (e.message === "data is null") {
        }
    }

    sendError(statusCode , data) {
        WindowLoading.value = false;
        console.log(data);
        
        ToastService.getInstance().error(data.data.msg ?? data.msg ? data.msg :'Error Trade')
    }

    getNewToken() {
        LocalStorageService.getInstance().removeItem(Constant.Token_Key)
    }
}

export class ReturnResponse {
    key?:any
    url?: string
    urlContent?: any
    httpMethod?: HttpMethod;
    body?: any
    onLoadChange?: (loadStatus: boolean) => void
    onReceive?: (res: any , headers?: any) => void
    onError?: (err: any ) => void
}

enum HttpMethod {
    post = 'post',
    get = 'get',
    delete = 'delete',
    put = 'put'
  }