
import { onMounted, reactive, toRefs, watch } from "vue";
import EmptyBox from "@/app/unit/shared/emptyBox/emptyBox.vue";
import { Constant } from "@/core/constant";
import store from "@/core/store";
import Checkbox from "primevue/checkbox";
import TransferComponent from "@/app/unit/components/TransferUsdToUsdtComp.vue";
import { AccountRepository } from "@/core/repository/account.repository";
import { TradeRepository } from "@/core/repository/trade.repository";
import { exportExel } from "@/core/service/exportExel";
import { getImage } from "@/core/helper/getImage";

export default {
  emits: ["initTransfer"],
  components: { EmptyBox, Checkbox, TransferComponent },
  computed: {
    getBalance() {
      return (amount) => {
        const data = store.state.wsAssets.data;

        return (Number(data?.USDT?.price_usd ?? 1) * amount).toFixed(8);
      };
    },
  },
  setup(props) {
    const state = reactive({
      showPrice: true,
      hideSmall: false,
      transferDialog: false,
      exportLoading: false,
      balance: {
        data: [],
        loading: false,
        total: 0,
      },
      historyData: null,
    });

    const onErrorImage = (e) => {
      e.target.src = Constant.ErrorImageUrl;
    };

    const GetSpotBalance = () => {
      TradeRepository.getInstance().GetHistoryUSD({
        onReceive: (res) => {
          state.balance.data = [];
          state.balance.data = res.exchange;
          console.log("====================================");
          console.log(res);
          console.log("====================================");
        },
        onLoadChange: (load) => (state.balance.loading = load),
      });
    };

    const watchBalance = () => {
      store.subscribe((mutation, state) => {
        if (
          mutation.type == "setMarketPrice" ||
          mutation.type == "updateBalance"
        ) {
          GetSpotBalance();
        }
      });
    };

    const onExport = () => {
      TradeRepository.getInstance().GetHistoryUSD({
        onReceive(res, headers) {
          const _list = res.exchange.map((item) => {
            const _item = {
              from: item.from,
              to: item.to,
              amount: item.amount,
              updatedAt: item.updatedAt,
            };

            return _item;
          });
          exportExel("USDT history", _list);
        },
        onLoadChange(loadStatus) {
          state.exportLoading = loadStatus;
        },
      });
    };
    onMounted(() => {
      GetSpotBalance();
      watchBalance();
    });

    return {
      ...toRefs(state),
      onErrorImage,
      GetSpotBalance,
      onExport,
      getImage,
    };
  },
};
